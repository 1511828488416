import React from 'react'

import Layout from '../components/layout'
import Header from '../components/header'
import Manners from '../components/manners'
import Water from '../components/water'
import Advantages from '../components/advantages'
import OtherInitiatives from '../components/other-initiatives'
import Contest from '../components/contest'
import Footer from '../components/footer'
import SEO from '../components/seo'
import AgeCheck from '../components/age-check'
import CookieBar from '../components/cookie-bar'

const IndexPage = () => (
  <Layout>
    <SEO title="" />

    <Header />
    <Manners />
    <Water />
    <Advantages />
    <OtherInitiatives />
    <Contest />
    <Footer />
    <AgeCheck />
    <CookieBar />
  </Layout>
)

export default IndexPage
