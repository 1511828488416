import React, { useState } from 'react'
import styled from 'styled-components'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import { Swipeable } from 'react-swipeable'
import {
  Col,
  Row,
  Carousel,
  CarouselItem,
  CarouselIndicators,
} from 'reactstrap'

import {
  CarouselControlWrapper,
  CarouselControl,
  CarouselControlItem,
  Button,
} from './elements'

const DrawerWrapper = styled.div`
  position: fixed;
  top: 50%;
  right: 0;
  width: 80%;
  max-width: 1110px;
  background-color: ${(props) => props.theme.colorWhite};
  padding-top: 50px;
  margin-right: ${(props) => (props.show ? 0 : -100)}%;
  transform: translateY(-50%);
  transition: margin-right 0.8s ease-in-out;
  z-index: 9999;
  @media (max-width: 1199px) {
    width: 90%;
  }
  @media (min-width: 768px) and (max-width: 991px) {
    width: 95%;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    width: 98%;
  }
  @media (max-width: 575px) {
    width: 100%;
  }
  .carousel {
    padding-left: 20px;
    padding-right: 20px;
  }
`

const DrawerCarouselItem = styled(CarouselItem)``

const DrawerDescColWrapper = styled(Col)`
  @media (max-width: 575px) {
    order: 2;
  }
`

const DrawerDescWrapper = styled.div`
  position: relative;
  @media (min-width: 576px) {
    left: 30%;
    padding-left: 20px;
    padding-right: 20px;
  }
  @media (max-width: 575px) {
    padding-bottom: 20px;
  }
`

const DrawerImageColWrapper = styled(Col)`
  @media (max-width: 575px) {
    order: 1;
  }
`

const DrawerButtonWrapper = styled.div`
  @media (min-width: 576px) {
    position: relative;
  }
`

const DrawerButtonClose = styled(Button)`
  position: absolute;
  left: 20px;
  bottom: 0;
  padding-left: 60px;
  padding-right: 35px;
  z-index: 20;
  @media (max-width: 1199px) {
    bottom: 30px;
  }
  @media (max-width: 575px) {
    top: 0;
    left: auto;
    right: 0;
    bottom: auto;
    padding-left: 60px;
    padding-right: 35px;
  }
  &:hover,
  &:focus {
    &:after {
      left: 10px;
    }
  }
  &:after {
    left: 15px;
    right: auto;
    transform: rotate(180deg);
  }
`

const DrawerCarouselControlWrapper = styled(CarouselControlWrapper)``

const DrawerCarouselControl = styled(CarouselControl)`
  margin-bottom: 3rem;
`

const DrawerCarouselControlItem = styled(CarouselControlItem)`
  margin-right: 230px;
  @media (min-width: 576px) and (max-width: 767px) {
    margin-right: 150px;
  }
  @media (max-width: 575px) {
    margin-right: 70px;
  }
  &:after {
    width: 230px;
    @media (min-width: 576px) and (max-width: 767px) {
      width: 150px;
    }
    @media (max-width: 575px) {
      width: 70px;
    }
  }
`

const DrawerOverlay = styled.div`
  ${(props) =>
    props.show
      ? `
    display: block;
    opacity: 0.5;
  `
      : `
    display: none;
    opacity: 1;
  `}
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${(props) => props.theme.colorBlack};
  transition: opacity 0.15s linear;
  z-index: 8000;
`

const Drawer = (props) => {
  const data = useStaticQuery(graphql`
    query {
      drawerImage1: file(relativePath: { eq: "drawer_1.png" }) {
        childImageSharp {
          fluid(maxWidth: 640) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      drawerImage2: file(relativePath: { eq: "drawer_2.png" }) {
        childImageSharp {
          fluid(maxWidth: 640) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      drawerImage3: file(relativePath: { eq: "drawer_3.png" }) {
        childImageSharp {
          fluid(maxWidth: 640) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  `)

  const items = [
    {
      caption: `filtracja, wsiąkanie`,
      desc: `1.	Dzięki odpowiedniej roślinności oraz warstwom filtrującym podłoże ogród deszczowy wstępnie oczyszcza wodę i wprowadza ją do głębszych warstw gleby.`,
    },
    {
      caption: `agregowanie wody`,
      desc: `2.	W ten sposób deszczówka zostaje zatrzymana w krajobrazie
      i pomaga dbać o okoliczną roślinność w trakcie suszy.`,
    },
    {
      caption: `parowanie`,
      desc: `3.	Rośliny chłoną wodę, a jej nadmiar paruje z jej nadziemnych części – dzięki temu poprawia się mikroklimat i zapobiega lokalnym podtopieniom.`,
    },
  ]

  const [activeIndex, setActiveIndex] = useState(0)
  const [animating, setAnimating] = useState(false)

  const next = () => {
    if (animating) return
    const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1
    setActiveIndex(nextIndex)
  }

  const previous = () => {
    if (animating) return
    const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1
    setActiveIndex(nextIndex)
  }

  const goToIndex = (newIndex) => {
    if (animating) return
    setActiveIndex(newIndex)
  }

  const slides = items.map((item, index) => {
    return (
      <DrawerCarouselItem
        onExiting={() => setAnimating(true)}
        onExited={() => setAnimating(false)}
        key={index}
      >
        <Row>
          <DrawerDescColWrapper xs={12} sm={4}>
            <DrawerDescWrapper>
              <p>{item.desc}</p>
            </DrawerDescWrapper>
          </DrawerDescColWrapper>
          <DrawerImageColWrapper xs={12} sm={8}>
            <Img
              fluid={data[`drawerImage${index + 1}`].childImageSharp.fluid}
            />
          </DrawerImageColWrapper>
        </Row>
      </DrawerCarouselItem>
    )
  })

  return (
    <>
      <DrawerWrapper className="drawerCarousel" show={props.show}>
        <Swipeable onSwipedLeft={() => previous()} onSwipedRight={() => next()}>
          <Carousel
            activeIndex={activeIndex}
            next={next}
            previous={previous}
            interval={false}
          >
            {false && (
              <CarouselIndicators
                items={items}
                activeIndex={activeIndex}
                onClickHandler={goToIndex}
              />
            )}
            {slides}
          </Carousel>
          <DrawerButtonWrapper>
            <DrawerButtonClose onClick={() => props.toggleDrawer()}>
              WRÓĆ
            </DrawerButtonClose>
          </DrawerButtonWrapper>
          <DrawerCarouselControlWrapper>
            <DrawerCarouselControl>
              {items.map((item, index) => {
                return (
                  <DrawerCarouselControlItem
                    key={index}
                    index={index}
                    current={activeIndex}
                    onClick={() => goToIndex(index)}
                  >
                    <span className="number">
                      <span>{index + 1}</span>
                    </span>
                    <span className="caption">{item.caption}</span>
                  </DrawerCarouselControlItem>
                )
              })}
            </DrawerCarouselControl>
          </DrawerCarouselControlWrapper>
        </Swipeable>
      </DrawerWrapper>
      <DrawerOverlay show={props.show} onClick={() => props.toggleDrawer()} />
    </>
  )
}

export default Drawer
