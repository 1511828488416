import React, { useState } from 'react'
import { ModalBody } from 'reactstrap'
import styled from 'styled-components'

import {
  CustomModal,
  CustomModalHeader,
  CustomModalCloseButton,
  CustomModalFooter,
  CustomModalPagination,
  CustomModalPaginationItem,
  CustomModalPaginationLink,
} from './elements'

const StyledModalBody = styled(ModalBody)`
  a {
    color: ${(props) => props.theme.textColor};
  }
`

const ModalBodyWrapper = styled.div``

export default ({ allItems, isOpen, onClose, type = null }) => {
  const [currentModalIndex, setCurrentModalIndex] = useState(0)

  const toggle = () => {
    setCurrentModalIndex(0)
    onClose()
  }

  const togglePagination = (event, index, type) => {
    event.preventDefault()

    if (type === 'next') {
      setCurrentModalIndex(index + 1)
    }

    if (type === 'prev') {
      setCurrentModalIndex(index - 1)
    }

    if (type === 'definite') {
      setCurrentModalIndex(index)
    }
  }

  return (
    <CustomModal
      isOpen={isOpen}
      toggle={toggle}
      className={'modal-dialog-centered modalAdvantageCard'}
    >
      <CustomModalHeader
        toggle={toggle}
        close={
          <CustomModalCloseButton onClick={toggle}>
            ZAMKNIJ X
          </CustomModalCloseButton>
        }
      >
        {allItems[currentModalIndex].title && (
          <span>
            <span
              dangerouslySetInnerHTML={{
                __html: allItems[currentModalIndex].title,
              }}
            />
          </span>
        )}
      </CustomModalHeader>

      <ModalBodyWrapper>
        {allItems[currentModalIndex].text && (
          <StyledModalBody
            type={type}
            dangerouslySetInnerHTML={{
              __html: allItems[currentModalIndex].text,
            }}
          />
        )}
        {allItems[currentModalIndex].image && (
          <div>{allItems[currentModalIndex].image}</div>
        )}
        {allItems[currentModalIndex].video && (
          <div>
            <video
              width="100%"
              height="auto"
              controls="controls"
              autoplay="autoplay"
            >
              <source
                src={`${allItems[currentModalIndex].video}.mp4`}
                type="video/mp4"
              />
              <source
                src={`${allItems[currentModalIndex].video}.ogv`}
                type="video/ogv"
              />
              <source
                src={`${allItems[currentModalIndex].video}.webm`}
                type="video/webm"
              />
            </video>
          </div>
        )}
      </ModalBodyWrapper>

      <CustomModalFooter>
        <CustomModalPagination size="sm" aria-label="Page navigation example">
          <CustomModalPaginationItem
            disabled={currentModalIndex === 0 ? true : false}
          >
            <CustomModalPaginationLink
              previous
              href="#"
              className="prev"
              onClick={(event) =>
                togglePagination(event, currentModalIndex, 'prev')
              }
            >
              POPRZEDNI
            </CustomModalPaginationLink>
          </CustomModalPaginationItem>
          {allItems.map((_, idx) => {
            return (
              <CustomModalPaginationItem key={idx}>
                <CustomModalPaginationLink
                  href="#"
                  current={(idx === currentModalIndex).toString()}
                  onClick={(event) => togglePagination(event, idx, 'definite')}
                >
                  {idx + 1}
                </CustomModalPaginationLink>
              </CustomModalPaginationItem>
            )
          })}
          <CustomModalPaginationItem
            disabled={currentModalIndex === allItems.length - 1 ? true : false}
          >
            <CustomModalPaginationLink
              next
              href="#"
              className="next"
              onClick={(event) =>
                togglePagination(event, currentModalIndex, 'next')
              }
            >
              NASTĘPNY
            </CustomModalPaginationLink>
          </CustomModalPaginationItem>
        </CustomModalPagination>
      </CustomModalFooter>
    </CustomModal>
  )
}
