import React, { useState } from 'react'
import Img from 'gatsby-image'
import styled from 'styled-components'
import { Col, Card, CardTitle, CardText, ModalBody } from 'reactstrap'

import {
  Button,
  CustomModal,
  CustomModalHeader,
  CustomModalCloseButton,
  CustomModalFooter,
  CustomModalPagination,
  CustomModalPaginationItem,
  CustomModalPaginationLink,
} from './elements'

const AdvantageItemCardWrapper = styled(Col)`
  margin-bottom: 70px;
  @media (max-width: 575px) {
    padding-right: 0;
    padding-left: 0;
    margin-bottom: 30px;
  }
`

const AdvantageItemCard = styled(Card)`
  position: relative;
  height: 100%;
  background-color: #f1ebdf;
  padding: 40px;
  border: none;
  border-radius: 0;
  box-shadow: 0px 33px 54px 0px rgba(111, 81, 49, 0.17);
  @media (min-width: 992px) and (max-width: 1199px) {
    padding: 30px;
    padding-bottom: 40px;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    padding: 25px;
  }
  @media (max-width: 575px) {
    box-shadow: none;
  }
  &:before {
    content: '';
    position: absolute;
    top: 15px;
    left: 15px;
    right: 15px;
    bottom: 15px;
    border: 1px solid #85764e;
    z-index: 10;
  }
  .rectangle {
    position: absolute;
    display: block;
    background-color: #f1ebdf;
    z-index: 20;
    &.topRectangle {
      top: 0;
      left: 50px;
      right: 50px;
      height: 40px;
    }
    &.bottomRectangle {
      width: 190px;
      height: 55px;
      right: 0;
      bottom: 0;
    }
  }
`

const AdvantageItemCardNumber = styled.span`
  position: absolute;
  top: -35px;
  left: 48px;
  font-size: 100px;
  font-style: italic;
  line-height: 0.32;
  z-index: 20;
  @media (min-width: 992px) and (max-width: 1199px) {
    font-size: 84px;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    top: -20px;
    font-size: 78px;
  }
`

const AdvantageItemCardTitle = styled(CardTitle)`
  position: relative;
  color: #800609;
  font-family: ${(props) => props.theme.fontSaaSeriesCD};
  font-size: 26px;
  font-weight: 400;
  margin-bottom: 30px;
  z-index: 20;
  @media (min-width: 992px) and (max-width: 1199px) {
    font-size: 24px;
    margin-bottom: 25px;
  }
  @media (min-width: 768px) and (max-width: 991px) {
    font-size: 24px;
    margin-bottom: 25px;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    font-size: 22px;
    margin-top: 10px;
    margin-bottom: 20px;
  }
`

const AdvantageItemCardText = styled(CardText)`
  position: relative;
  margin-bottom: 20px;
  z-index: 20;
`

const AdvantageItemCardButton = styled(Button)`
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 20;
  @media (max-width: 575px) {
    right: 15px;
    bottom: 15px;
  }
`

const AdvantageItemModal = styled(CustomModal)``

const AdvantageItemModalHeader = styled(CustomModalHeader)`
  @media (min-width: 1025px) {
    margin-top: 0.85em;
  }
`

const AdvantageItemModalCloseButton = styled(CustomModalCloseButton)``

const AdvantageItemModalFooter = styled(CustomModalFooter)``

const AdvantageItemModalPagination = styled(CustomModalPagination)``

const AdvantageItemModalPaginationItem = styled(CustomModalPaginationItem)``

const AdvantageItemModalPaginationLink = styled(CustomModalPaginationLink)``

const AdvantageItemModalBottomImageWrapper = styled.div`
  padding-left: 28%;
  padding-right: 28%;
  @media (max-width: 575px) {
    padding-left: 8%;
    padding-right: 8%;
  }
`

const AdvantageItem = (props) => {
  const index = props.index
  const { title, shortText } = props.items[index]
  const allItems = props.items
  const className = props.className

  const type = props.type ? props.type : null
  const images = props.images ? props.images : null

  const [modal, setModal] = useState(false)
  const [currentModalIndex, setCurrentModalIndex] = useState(index)

  const toggle = () => {
    setCurrentModalIndex(index)
    setModal(!modal)
  }

  const togglePagination = (event, index, type) => {
    event.preventDefault()
    console.log(index)

    if (type === 'next') {
      setCurrentModalIndex(index + 1)
    }

    if (type === 'prev') {
      setCurrentModalIndex(index - 1)
    }

    if (type === 'definite') {
      setCurrentModalIndex(index)
    }
  }

  return (
    <>
      <AdvantageItemCardWrapper className={className} sm="6" lg="4">
        <AdvantageItemCard body>
          <span className="rectangle bottomRectangle" />
          <span className="rectangle topRectangle" />
          <AdvantageItemCardNumber>{index + 1}.</AdvantageItemCardNumber>
          <AdvantageItemCardTitle
            as="h3"
            dangerouslySetInnerHTML={{ __html: title }}
          />
          <AdvantageItemCardText>{shortText}</AdvantageItemCardText>
          <AdvantageItemCardButton onClick={toggle}>
            coś więcej
          </AdvantageItemCardButton>
        </AdvantageItemCard>
      </AdvantageItemCardWrapper>

      <AdvantageItemModal
        isOpen={modal}
        toggle={toggle}
        className={'modal-dialog-centered modalAdvantageCard'}
      >
        <AdvantageItemModalHeader
          toggle={toggle}
          close={
            <AdvantageItemModalCloseButton onClick={toggle}>
              ZAMKNIJ X
            </AdvantageItemModalCloseButton>
          }
        >
          <span>
            <span>{currentModalIndex + 1}.</span>{' '}
            <span
              dangerouslySetInnerHTML={{
                __html: allItems[currentModalIndex].title,
              }}
            />
          </span>
        </AdvantageItemModalHeader>
        <div>
          <ModalBody
            dangerouslySetInnerHTML={{
              __html: allItems[currentModalIndex].text,
            }}
          />
          {type === 'adwantage' && images && (
            <AdvantageItemModalBottomImageWrapper>
              <Img
                fluid={
                  images[`advantagesSlideImage${currentModalIndex + 1}`]
                    .childImageSharp.fluid
                }
              />
            </AdvantageItemModalBottomImageWrapper>
          )}
        </div>
        <AdvantageItemModalFooter>
          <AdvantageItemModalPagination
            size="sm"
            aria-label="Page navigation example"
          >
            <AdvantageItemModalPaginationItem
              disabled={currentModalIndex === 0 ? true : false}
            >
              <AdvantageItemModalPaginationLink
                previous
                href="#"
                className="prev"
                onClick={(event) =>
                  togglePagination(event, currentModalIndex, 'prev')
                }
              >
                POPRZEDNI
              </AdvantageItemModalPaginationLink>
            </AdvantageItemModalPaginationItem>
            {allItems.map((_, idx) => {
              return (
                <AdvantageItemModalPaginationItem key={idx}>
                  <AdvantageItemModalPaginationLink
                    href="#"
                    current={(idx === currentModalIndex).toString()}
                    onClick={(event) =>
                      togglePagination(event, idx, 'definite')
                    }
                  >
                    {idx + 1}
                  </AdvantageItemModalPaginationLink>
                </AdvantageItemModalPaginationItem>
              )
            })}
            <AdvantageItemModalPaginationItem
              disabled={
                currentModalIndex === allItems.length - 1 ? true : false
              }
            >
              <AdvantageItemModalPaginationLink
                next
                href="#"
                className="next"
                onClick={(event) =>
                  togglePagination(event, currentModalIndex, 'next')
                }
              >
                NASTĘPNY
              </AdvantageItemModalPaginationLink>
            </AdvantageItemModalPaginationItem>
          </AdvantageItemModalPagination>
        </AdvantageItemModalFooter>
      </AdvantageItemModal>
    </>
  )
}

export default AdvantageItem
