import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import { Link as LinkScroll } from 'react-scroll'
import styled from 'styled-components'
import { Container, Row, Col } from 'reactstrap'

import footerBGImage from '../images/footer_bg.svg'

const FooterWrapper = styled.div`
  background-repeat: no-repeat;
  background-position: center bottom;
  background-size: contain;
  color: ${(props) => props.theme.colorBlack};
  font-family: ${(props) => props.theme.fontSaaSeriesCD};
  font-size: 16px;
  font-weight: 400;
  padding-bottom: 50px;
  @media (min-width: 576px) {
    background-image: url(${footerBGImage});
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    padding-bottom: 35px;
  }
  @media (min-width: 768px) and (max-width: 991px) {
    padding-bottom: 25px;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    font-size: 14px;
    padding-bottom: 20px;
  }
  @media (max-width: 575px) {
    padding-bottom: 15px;
  }
  .container {
    padding-left: 20px;
    padding-right: 20px;
  }
`

const FooterLogoColWrapper = styled(Col)`
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 575px) {
    flex: 0 0 100%;
    max-width: 100%;
    order: -1;
    margin-bottom: 30px;
  }
`

const FooterLogoWrapper = styled.div`
  width: 80px;
  height: 100%;
  margin-left: 0;
  margin-right: auto;
  @media (min-width: 768px) and (max-width: 991px) {
    display: flex;
    align-items: center;
    width: 50px;
    height: 100%;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    display: flex;
    align-items: center;
    width: 60px;
    height: 100%;
  }
  @media (max-width: 575px) {
    width: 17%;
  }
  & > div {
    @media (min-width: 576px) and (max-width: 991px) {
      width: 100%;
    }
  }
`

const FooterLogosThirdWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 60%;
  height: 100%;
  margin-left: auto;
  margin-right: 0;
  @media (max-width: 575px) {
    width: 75%;
  }
  & > div {
    display: inline-block;
    width: 50%;
    &:first-of-type {
    }
    &:last-of-type {
    }
  }
`

const FooterList = styled.ul`
  display: inline-block;
  list-style-type: none;
  padding-left: 0;
  margin-bottom: 0;
`

const FooterListItem = styled.li``

const FooterLink = styled.a`
  color: ${(props) => props.theme.colorBlack};
  @media (min-width: 576px) and (max-width: 767px) {
    font-size: 13px;
  }
  @media (max-width: 575px) {
    font-size: 14px;
  }
  @media (max-width: 340px) {
    font-size: 13px;
  }
  &:hover,
  &:focus {
    color: ${(props) => props.theme.colorBlack};
  }
`

const FooterListLeft = styled(FooterList)`
  &:first-of-type {
    padding-right: 20px;
    @media (max-width: 575px) {
      padding-right: 15px;
    }
    @media (max-width: 340px) {
      padding-right: 10px;
    }
  }
  &:last-of-type {
    position: relative;
    padding-left: 20px;
    @media (max-width: 575px) {
      padding-left: 15px;
    }
    @media (max-width: 340px) {
      padding-left: 10px;
    }
    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      display: block;
      width: 1px;
      background-color: ${(props) => props.theme.colorBlack};
    }
  }
`

const FooterColRightWrapper = styled(Col)`
  text-align: right;
`

const FooterListRight = styled(FooterList)`
  text-align: left;
`

const FooterColWarning = styled(Col)`
  text-align: center;
`

const FooterWarningImageWrapper = styled.div`
  display: inline-block;
  width: 280px;
  padding-top: 20px;
  @media (max-width: 991px) {
    width: 255px;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    width: 235px;
  }
  @media (max-width: 575px) {
    width: 220px;
    padding-top: 15px;
  }
`

const Footer = () => {
  const data = useStaticQuery(graphql`
    query {
      logoImage1: file(relativePath: { eq: "logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 120) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      logoImage2: file(relativePath: { eq: "logo_third_party1.png" }) {
        childImageSharp {
          fluid(maxWidth: 200) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      logoImage3: file(relativePath: { eq: "logo_third_party2.png" }) {
        childImageSharp {
          fluid(maxWidth: 200) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      warningImage: file(relativePath: { eq: "warning_drivers.png" }) {
        childImageSharp {
          fluid(maxWidth: 320) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  `)

  return (
    <FooterWrapper>
      <Container>
        <Row>
          <Col xs={6} sm={4} md={4} lg={3} xl={3}>
            <FooterListLeft>
              <FooterListItem>
                <FooterLink as={LinkScroll} href="#water" to="water" smooth>
                  WODA
                </FooterLink>
              </FooterListItem>
              <FooterListItem>
                <FooterLink as={LinkScroll} href="#manners" to="manners" smooth>
                  PIWO
                </FooterLink>
              </FooterListItem>
              <FooterListItem>
                <FooterLink as={LinkScroll} href="#gardens" to="gardens" smooth>
                  OGRODY
                </FooterLink>
              </FooterListItem>
            </FooterListLeft>
            <FooterListLeft>
              <FooterListItem>
                <FooterLink
                  as={LinkScroll}
                  href="#rainwater"
                  to="rainwater"
                  smooth
                >
                  DESZCZÓWKA
                </FooterLink>
              </FooterListItem>
              <FooterListItem>
                <FooterLink as={LinkScroll} href="#manners" to="manners" smooth>
                  OBYCZAJE
                </FooterLink>
              </FooterListItem>
              <FooterListItem>
                <FooterLink
                  as={LinkScroll}
                  href="#contest"
                  to="contest"
                  offset={-50}
                  smooth
                >
                  KONKURSY
                </FooterLink>
              </FooterListItem>
            </FooterListLeft>
          </Col>
          <FooterLogoColWrapper xs={12} sm={4} md={5} lg={6} xl={7}>
            <FooterLogoWrapper>
              <div>
                <Img fluid={data.logoImage1.childImageSharp.fluid} />
              </div>
            </FooterLogoWrapper>
            <FooterLogosThirdWrapper>
              <div>
                <Img fluid={data.logoImage2.childImageSharp.fluid} />
              </div>
              <div>
                <Img fluid={data.logoImage3.childImageSharp.fluid} />
              </div>
            </FooterLogosThirdWrapper>
          </FooterLogoColWrapper>
          <FooterColRightWrapper xs={6} sm={4} md={3} lg={3} xl={2}>
            <FooterListRight>
              <FooterListItem>
                <FooterLink
                  href="/polityka-prywatnosci.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  POLITKA PRYWATNOŚCI
                </FooterLink>
              </FooterListItem>
              <FooterListItem>
                <FooterLink
                  href="http://browarkasztelan.pl"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  BROWARKASZTELAN.PL
                </FooterLink>
              </FooterListItem>
              <FooterListItem>
                <FooterLink
                  href="https://mailme.ccp.com.pl/Carlsberg/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  KONTAKT
                </FooterLink>
              </FooterListItem>
            </FooterListRight>
          </FooterColRightWrapper>
          <FooterColWarning xs={12}>
            <FooterWarningImageWrapper>
              <Img fluid={data.warningImage.childImageSharp.fluid} />
            </FooterWarningImageWrapper>
          </FooterColWarning>
        </Row>
      </Container>
    </FooterWrapper>
  )
}

export default Footer
