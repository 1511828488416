import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import BackgroundImage from 'gatsby-background-image'
import styled from 'styled-components'
import { Container } from 'reactstrap'

import HeaderNavbar from './header-navbar'
import { Title } from './elements'

const HeaderWrapper = styled.div``

const HeaderBGWrapper = styled(BackgroundImage)`
  background-position-y: top;
`

const HeaderTitle = styled(Title)`
  color: ${(props) => props.theme.colorWhite};
  font-family: ${(props) => props.theme.fontSaaSeriesCD};
  font-size: 56px;
  font-weight: 400;
  text-align: center;
  padding-bottom: 70%;
  margin-top: 60px;
  margin-bottom: 0;
  @media (min-width: 992px) and (max-width: 1199px) {
    padding-bottom: 50%;
  }
  @media (min-width: 768px) and (max-width: 991px) {
    padding-bottom: 65%;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    font-size: 40px;
    padding-bottom: 115%;
    margin-top: 0;
  }
  @media (max-width: 575px) {
    font-size: 36px;
    padding-bottom: 350px;
  }
  br {
    &:first-of-type {
      @media (max-width: 575px) {
        display: none;
      }
    }
  }
`

const Header = () => {
  const data = useStaticQuery(graphql`
    query {
      headerBGImage: file(relativePath: { eq: "header_bg.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  `)

  return (
    <HeaderWrapper>
      <HeaderBGWrapper
        Tag="div"
        className="headerBGImage"
        fluid={data.headerBGImage.childImageSharp.fluid}
        backgroundColor={`#cb0e32`}
      >
        <Container>
          <HeaderNavbar />
          <HeaderTitle as="h1">
            Raczcie
            <br /> wpaść!
          </HeaderTitle>
        </Container>
      </HeaderBGWrapper>
    </HeaderWrapper>
  )
}

export default Header
