import React, { useState } from 'react'
import styled from 'styled-components'
import Img from 'gatsby-image'
import { useStaticQuery, graphql } from 'gatsby'
import BackgroundImage from 'gatsby-background-image'
import { Col, Row, Container, Modal, ModalBody } from 'reactstrap'

import { Button, Title } from './elements'

import ageCheckLineBGImage from '../images/age_check_line_bg.svg'
import ageCheckCrownIcon from '../images/crown_icon.svg'
import ageCheckConeIcon from '../images/cone_icon.svg'

const AgeCheckModal = styled(Modal)`
  width: 100%;
  height: 100%;
  max-width: 100% !important;
  margin: 0;
  .modal-content {
    width: 100%;
    height: 100%;
    border: none;
    border-radius: 0;
  }
`

const AgeCheckModalBody = styled(ModalBody)`
  padding: 0;
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 42px;
    background-image: url(${ageCheckLineBGImage});
    background-repeat: no-repeat;
    background-position: top center;
    background-size: cover;
    z-index: 20;
    @media (min-width: 576px) and (max-width: 767px) {
      height: 32px;
    }
    @media (max-width: 575px) {
      height: 24px;
    }
  }
`

const AgeCheckBGWrapper = styled(BackgroundImage)`
  width: 100%;
  height: 100vh;
`

const AgeCheckLogoImageWrapper = styled.div`
  width: 250px;
  padding-top: 10%;
  margin-left: auto;
  margin-right: auto;
  @media (max-width: 575px) {
    width: 180px;
    padding-top: 10%;
  }
`

const AgeCheckTitle = styled(Title)`
  text-align: center;
  margin-top: 50px;
  margin-bottom: 50px;
  @media (max-width: 575px) {
    margin-top: 30px;
    margin-bottom: 30px;
  }
`

const AgeCheckButtonWrapperLeft = styled(Col)`
  text-align: right;
  padding-right: 25px;
`

const AgeCheckButtonWrapperRight = styled(Col)`
  text-align: left;
  padding-left: 25px;
`

const AgeCheckButton = styled(Button)`
  display: inline-block;
  font-size: 24px;
  padding-left: 70px;
  padding-right: 45px;
  @media (max-width: 575px) {
    padding-left: 55px;
    padding-right: 35px;
  }
  &:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 26px;
    display: block;
    width: 22px;
    height: 22px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    margin-top: -11px;
    ${(props) =>
      props.number === 1 &&
      `
      background-image: url(${ageCheckCrownIcon});
    `}
    ${(props) =>
      props.number === 2 &&
      `
      background-image: url(${ageCheckConeIcon});
    `}
    @media (max-width: 575px) {
      left: 22px;
      width: 18px;
      height: 18px;
      margin-top: -9px;
    }
  }
  &:after {
    content: none;
  }
`
const AgeCheckListLinks = styled.ul`
  display: block;
  text-align: center;
  line-height: 1.6;
  padding-left: 0;
  margin-top: 12%;
`

const AgeCheckListItem = styled.li`
  display: inline-block;
  padding-left: 10px;
  padding-right: 10px;
  @media (max-width: 991px) {
    padding-left: 5px;
    padding-right: 5px;
  }
`

const AgeCheckListLink = styled.a`
  color: ${(props) => props.theme.colorWhite};
  font-size: 16px;
  text-transform: uppercase;
  padding-left: 15px;
  padding-right: 15px;
  @media (max-width: 991px) {
    padding-left: 10px;
    padding-right: 10px;
  }
  @media (min-width: 768px) and (max-width: 991px) {
    font-size: 15px;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    font-size: 15px;
  }
  @media (max-width: 575px) {
    font-size: 12px;
    padding-left: 5px;
    padding-right: 5px;
  }
  &:hover,
  &:focus {
    color: ${(props) => props.theme.colorWhite};
  }
`

const AgeCheck = (props) => {
  const data = useStaticQuery(graphql`
    query {
      logoImage: file(relativePath: { eq: "logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 250) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      modalBGImage: file(relativePath: { eq: "age_check_bg.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  `)
  const [modal, setModal] = useState(true)
  const toggle = () => setModal(!modal)

  return (
    <AgeCheckModal isOpen={modal} toggle={toggle} fade={false}>
      <AgeCheckModalBody>
        <AgeCheckBGWrapper
          Tag="div"
          className="ageCheckBGImage"
          fluid={data.modalBGImage.childImageSharp.fluid}
          backgroundColor={`#a51d24`}
        >
          <Container>
            <Row>
              <Col>
                <AgeCheckLogoImageWrapper>
                  <Img fluid={data.logoImage.childImageSharp.fluid} />
                </AgeCheckLogoImageWrapper>
                <AgeCheckTitle>
                  czy masz <br /> ukończone 18 lat?
                </AgeCheckTitle>
              </Col>
            </Row>
            <Row>
              <AgeCheckButtonWrapperLeft>
                <AgeCheckButton number={1} onClick={toggle}>
                  TAK
                </AgeCheckButton>
              </AgeCheckButtonWrapperLeft>
              <AgeCheckButtonWrapperRight>
                <AgeCheckButton
                  as="a"
                  href="http://google.pl/"
                  className="btn"
                  number={2}
                >
                  NIE
                </AgeCheckButton>
              </AgeCheckButtonWrapperRight>
            </Row>
            <Row>
              <Col>
                <AgeCheckListLinks>
                  <AgeCheckListItem>
                    <AgeCheckListLink
                      href="/regulamin-konkursu.pdf"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Regulamin
                    </AgeCheckListLink>
                  </AgeCheckListItem>
                  <AgeCheckListItem>
                    <AgeCheckListLink
                      href="/polityka-prywatnosci.pdf"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Polityka&nbsp;prywatności
                    </AgeCheckListLink>
                  </AgeCheckListItem>
                  <AgeCheckListItem>
                    <AgeCheckListLink href="/">
                      Pliki&nbsp;cookies
                    </AgeCheckListLink>
                  </AgeCheckListItem>
                  <AgeCheckListItem>
                    <AgeCheckListLink href="/">
                      warunki&nbsp;korzystania
                    </AgeCheckListLink>
                  </AgeCheckListItem>
                  <AgeCheckListItem>
                    <AgeCheckListLink
                      href="https://mailme.ccp.com.pl/Carlsberg/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Kontakt
                    </AgeCheckListLink>
                  </AgeCheckListItem>
                </AgeCheckListLinks>
              </Col>
            </Row>
          </Container>
        </AgeCheckBGWrapper>
      </AgeCheckModalBody>
    </AgeCheckModal>
  )
}

export default AgeCheck
