import styled, { css } from 'styled-components'
import {
  Button as Btn,
  Col,
  Modal,
  ModalHeader,
  ModalFooter,
  Pagination,
  PaginationItem,
  PaginationLink,
} from 'reactstrap'
import { Form, Field } from 'formik'

import arrowBtnIcon from '../images/arrow_btn_icon.svg'
import formAarrowTopIcon from '../images/form_arrow_top_icon.svg'
import formAarrowDownIcon from '../images/form_arrow_down_icon.svg'
import contestDragDotsBGImage from '../images/contest_drag_dots_bg.svg'
import formCheckedIcon from '../images/form_checked_icon.svg'

import paginationArrowBlackLeftIcon from '../images/pagination_arrow_black_left.svg'
import paginationArrowBlackRightIcon from '../images/pagination_arrow_black_right.svg'
import paginationArrowBrownkLeftIcon from '../images/pagination_arrow_brown_left.svg'
import paginationArrowBrownRightIcon from '../images/pagination_arrow_brown_right.svg'

export const CarouselFadeMixin = css/* styled */ `
  .carousel-fade .carousel-item {
    opacity: 0;
    transition: opacity ease-out 0.7s;
  }

  .carousel-fade .carousel-item.active,
  .carousel-fade .carousel-item-next.carousel-item-left,
  .carousel-fade .carousel-item-prev.carousel-item-right {
    opacity: 1;
  }

  .carousel-fade .active.carousel-item-left,
  .carousel-fade .active.carousel-item-right {
    opacity: 0;
  }

  .carousel-fade .carousel-item-next,
  .carousel-fade .carousel-item-prev,
  .carousel-fade .carousel-item.active,
  .carousel-fade .active.carousel-item-left,
  .carousel-fade .active.carousel-item-prev {
    transform: translateX(0);
    transform: translate3d(0, 0, 0);
  }
`

export const ContainerPaddingsMixin = css/* styled */ `
  padding-left: 40px;
  padding-right: 40px;
`

export const Button = styled(Btn)`
  position: relative;
  background-color: ${(props) => props.theme.colorBlack};
  color: ${(props) => props.theme.colorWhite};
  font-family: ${(props) => props.theme.fontSaaSeriesCD};
  font-size: 18px;
  line-height: 1.4;
  padding-left: 35px;
  padding-right: 60px;
  border: none !important;
  border-radius: 0;
  box-shadow: none !important;
  transition: 0.2s ease;
  @media only screen and (-webkit-min-device-pixel-ratio: 1.5),
    only screen and (min--moz-device-pixel-ratio: 1.5),
    only screen and (-o-min-device-pixel-ratio: 3/2),
    only screen and (min-device-pixel-ratio: 1.5),
    only screen and (min-resolution: 192dpi) {
    padding-top: 0.575rem;
  }
  @media (min-width: 768px) and (max-width: 991px) {
    font-size: 16px;
  }
  @media (min-width: 575px) and (max-width: 767px) {
    font-size: 16px;
  }
  @media (max-width: 575px) {
    font-size: 18px;
    padding-left: 20px;
  }
  &:hover,
  &:focus {
    background-color: ${(props) => props.theme.colorBlack};
    color: ${(props) => props.theme.colorWhite};
    opacity: 1;
    &:after {
      right: 10px;
    }
  }
  &:after {
    content: '';
    position: absolute;
    top: 50%;
    right: 15px;
    display: block;
    width: 18px;
    height: 14px;
    background-image: url(${arrowBtnIcon});
    background-repeat: no-repeat;
    background-position: center right;
    background-size: contain;
    margin-top: -7px;
  }
`

export const ButtonCollapse = styled(Btn)`
  position: relative;
  min-width: 85px;
  background-color: transparent;
  color: ${(props) => props.theme.colorBlack};
  font-family: ${(props) => props.theme.fontSaaSeriesCD};
  font-size: 18px;
  font-weight: 700;
  text-align: left;
  text-decoration: underline;
  line-height: 1.4;
  padding-left: 0;
  padding-right: 0;
  border: none !important;
  border-radius: 0;
  box-shadow: none !important;
  transition: 0.2s ease;
  @media only screen and (-webkit-min-device-pixel-ratio: 1.5),
    only screen and (min--moz-device-pixel-ratio: 1.5),
    only screen and (-o-min-device-pixel-ratio: 3/2),
    only screen and (min-device-pixel-ratio: 1.5),
    only screen and (min-resolution: 192dpi) {
    padding-top: 0.575rem;
  }
  &:hover,
  &:focus,
  &:active {
    background-color: transparent !important;
    color: ${(props) => props.theme.colorBlack}!important;
    text-decoration: underline;
    border: none !important;
    opacity: 1;
    &:after {
    }
  }
  &:after {
    content: '';
    position: absolute;
    top: 5px;
    right: 0;
    bottom: 5px;
    display: block;
    width: 18px;
    background-image: ${(props) =>
      props.isopen === 'true'
        ? `
      url(${formAarrowTopIcon})
    `
        : `
      url(${formAarrowDownIcon})
    `};
    background-repeat: no-repeat;
    background-position: center right;
    background-size: contain;
    margin-top: -7px;
  }
`

export const Title = styled.h2`
  color: ${(props) => props.theme.colorWhite};
  font-family: ${(props) => props.theme.fontSaaSeriesCD};
  font-size: 56px;
  font-weight: 400;
  letter-spacing: -0.065rem;
  @media (min-width: 992px) and (max-width: 1199px) {
    font-size: 48px;
  }
  @media (min-width: 768px) and (max-width: 991px) {
    font-size: 38px;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    font-size: 28px;
  }
  @media (max-width: 575px) {
    font-size: 36px;
  }
`

export const SubmitForm = styled(Form)`
  font-family: ${(props) => props.theme.fontMontserrat};
  font-size: 12px;
  @media (max-width: 575px) {
    font-size: 10px;
  }
`

export const SubmitFormRow = styled.div``

export const SubmitFormGroup = styled(Col)`
  margin-bottom: 30px;
  @media (min-width: 768px) {
    ${(props) =>
      props.align === 'left' &&
      `
    padding-right: 15px!important;  
  `}
    ${(props) =>
      props.align === 'right' &&
      `
    padding-left: 15px!important;
  `}
  }
  @media (max-width: 575px) {
    margin-bottom: 15px;
  }
`

export const SubmitFormField = styled(Field)`
  height: 50px;
  background-color: ${(props) => props.theme.colorWhite};
  color: #a19187;
  font-family: ${(props) => props.theme.fontMontserrat};
  font-size: 18px;
  border: 1px solid ${(props) => props.theme.colorBlack};
  border-radius: 0;
  ${(props) =>
    props.error &&
    `
    border-color: ${props.theme.colorRed};
  `}
  @media (max-width: 575px) {
    font-size: 16px;
  }
  &:focus {
    ${(props) =>
      props.error &&
      `
      color: #a19187;
      border-color: ${props.theme.colorRed};
      box-shadow: 0 0 0 0.2rem rgba(171,38,45,.35);
    `}
  }
`

export const SubmitFormDragWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 124px;
  background-color: #f1ebdf;
  color: ${(props) => props.theme.colorBlack};
  font-family: ${(props) => props.theme.fontSaaSeriesCD};
  font-size: 18px;
  font-weight: 700;
  text-align: center;
  border-radius: 0;
  cursor: pointer;
  &:focus {
    border: none;
    outline: none;
  }
  &:before {
    content: '';
    position: absolute;
    top: 0.75rem;
    left: 0.75rem;
    right: 0.75rem;
    bottom: 0.75rem;
    background-image: url(${contestDragDotsBGImage});
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
  }
  p {
    margin-bottom: 0;
    span {
      text-decoration: underline;
    }
  }
`

export const SubmitFormCheckBoxField = styled(Field)`
  &:checked {
    & ~ .custom-control-label {
      &:before {
        background-color: transparent;
        background-image: url(${formCheckedIcon});
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
      }
    }
  }
`

export const SubmitFormCheckBoxLabel = styled.label`
  padding-left: 10px;
  cursor: pointer;
  &:before,
  &:after {
    top: -0.25rem;
    left: -1.25rem;
    width: 18px;
    height: 18px;
    border: 1px solid #787878;
    ${(props) =>
      props.error &&
      `
      border-color: ${props.theme.colorRed};
    `}
    &:focus {
      ${(props) =>
        props.error &&
        `
      box-shadow: 0 0 0 0.2rem rgba(171,38,45,.35);
    `}
    }
  }
  &:before {
  }
  &:after {
  }
`

export const SubmitFormCollapseWrapper = styled.div`
  padding-left: 30px;
`

export const SubmitFormCollapse = styled.div`
  overflow: hidden;
  margin-top: 1rem;
  transition: 0.3s ease;
  ${(props) =>
    !props.isOpen &&
    `
    height: 120px;
  `}
  margin-bottom: 1rem;
  p {
    &:last-of-type {
      margin-bottom: 0;
    }
  }
`

export const SubmitFormSubmitWrapper = styled.div`
  text-align: right;
  p {
    text-align: left;
    padding-left: 30px;
    margin-bottom: 2rem;
  }
`

export const CarouselControlWrapper = styled.div`
  width: 100%;
  overflow: hidden;
  margin-bottom: 15px;
`

export const CarouselControl = styled.ul`
  display: block;
  width: 100%;
  list-style-type: none;
  text-align: center;
  padding-left: 0;
`

export const CarouselControlItem = styled.li`
  position: relative;
  display: inline-block;
  width: 46px;
  height: 46px;
  color: ${(props) => props.theme.colorBlack};
  font-size: 30px;
  margin-right: 24px;
  border: 1px solid #252525;
  border-radius: 50%;
  ${(props) =>
    props.current === props.index &&
    `
    background-color: #e1d7c3;
  `}
  cursor: pointer;
  &:first-of-type {
    &:before {
      position: absolute;
      width: 2000px;
      right: 100%;
    }
  }
  &:last-of-type {
    margin-right: 0;
    &:after {
      width: 2000px;
    }
  }
  &:before,
  &:after {
    content: '';
    position: absolute;
    top: 50%;
    display: block;
    height: 1px;
    background-color: ${(props) => props.theme.colorBlack};
  }
  &:before {
  }
  &:after {
    width: 24px;
    left: 100%;
  }

  & > span {
    &.number {
      display: block;
      width: 46px;
      height: 46px;
      line-height: 1;
    }
    &.caption {
      position: absolute;
      left: 100%;
      bottom: -15px;
      color: ${(props) => props.theme.colorBlack};
      font-family: ${(props) => props.theme.fontSaaSeriesCD};
      font-size: 16px;
      text-transform: uppercase;
      white-space: nowrap;
      margin-left: 15px;
      ${(props) =>
        props.current === props.index &&
        `
          color: ${props.theme.colorGreen};
        `}
      @media (max-width: 991px) {
        left: auto;
        bottom: -30px;
        margin-left: auto;
        transform: translateX(-50%);
      }
      @media (max-width: 575px) {
        font-size: 13px;
      }
      @media (max-width: 340px) {
        font-size: 10px;
      }
    }
  }
`

export const CustomModal = styled(Modal)`
  position: relative;
  /* background-color: #f1ebdf; */
  padding: 15px;
  box-shadow: 0px 33px 54px 0px rgba(111, 81, 49, 0.17);
  @media (min-width: 992px) {
    max-width: 720px;
  }
  .modal-content {
    background-color: #f1ebdf;
    padding: 30px;
    padding-bottom: 15px;
    border: 1px solid #85764e;
    border-radius: 0;
    @media (max-width: 575px) {
      padding: 15px;
    }
    &:before {
      content: '';
      position: absolute;
      top: -15px;
      right: -15px;
      display: block;
      width: 135px;
      height: 55px;
      background-color: #f1ebdf;
    }
    &:after {
      content: '';
      position: absolute;
      top: -15px;
      left: -15px;
      right: -15px;
      bottom: -15px;
      display: block;
      background-color: #f1ebdf;
      z-index: -10;
    }
  }
`

export const CustomModalHeader = styled(ModalHeader)`
  border: none;
  .modal-title {
    color: #800609;
    font-family: ${(props) => props.theme.fontSaaSeriesCD};
    font-size: 56px;
    font-weight: 400;
    line-height: 1;
    @media (min-width: 768px) and (max-width: 991px) {
      font-size: 44px;
    }
    @media (min-width: 576px) and (max-width: 767px) {
      font-size: 42px;
    }
    @media (max-width: 575px) {
      font-size: 36px;
    }
    span {
      display: block;
      @media (min-width: 1025px) {
        line-height: 2;
        margin-top: -0.3em;
        margin-bottom: -0.3em;
      }
      @media (max-width: 575px) {
        margin-top: 0.25em;
      }
      span {
        display: inline-block;
        font-weight: normal;
        @media (min-width: 1025px) {
          line-height: 1.2;
        }
      }
    }
  }
`

export const CustomModalCloseButton = styled(Button)`
  position: absolute;
  top: -16px;
  right: -16px;
  padding-left: 25px;
  padding-right: 25px;
  &:after {
    content: none;
  }
`

export const CustomModalFooter = styled(ModalFooter)`
  justify-content: center;
  border: none;
`

export const CustomModalPagination = styled(Pagination)`
  .pagination {
    margin-bottom: 0;
  }
`

export const CustomModalPaginationItem = styled(PaginationItem)`
  .page-link {
    ${(props) =>
      props.disabled &&
      `
      background-color: transparent!important;
      color: #ded3be!important;
    `}
    &.prev {
      &:before {
        ${(props) =>
          props.disabled &&
          `
          background-image: url(${paginationArrowBrownkLeftIcon});
        `}
      }
    }
    &.next {
      &:before {
        ${(props) =>
          props.disabled &&
          `
          background-image: url(${paginationArrowBrownRightIcon});
        `}
      }
    }
  }
`

export const CustomModalPaginationLink = styled(PaginationLink)`
  background-color: transparent;
  color: ${(props) => props.theme.colorBlack};
  font-family: ${(props) => props.theme.fontSaaSeriesCD};
  font-size: 18px;
  font-weight: 400;
  border: none;
  border-radius: 0;
  ${(props) =>
    props.current === 'true' &&
    `
    color: #4a7729!important;
  `}
  &:hover,
  &:focus {
    background-color: transparent;
    color: #4a7729;
    outline: none;
    box-shadow: none;
  }
  &.prev,
  &.next {
    position: relative;
    &:before {
      content: '';
      position: absolute;
      top: 50%;
      display: block;
      width: 14px;
      height: 24px;
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      margin-top: -14px;
    }
  }
  &.prev {
    padding-right: 30px;
    &:before {
      right: 5px;
      background-image: url(${paginationArrowBlackLeftIcon});
    }
  }
  &.next {
    padding-left: 30px;
    &:before {
      left: 5px;
      background-image: url(${paginationArrowBlackRightIcon});
    }
  }
`

export const WithBubblesWrapper = styled.div`
  overflow: hidden;
  top: 550px;
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  z-index: 20;
  @media (min-width: 1921px) {
    top: 44%;
  }
  ${[...Array(20)].map((_, idx) => {
    const startWith = 1920
    const startTop = 44
    const step = idx + 1
    const multiplier = 100
    const maxWith = startWith + step * multiplier - 1
    const minWith = maxWith - multiplier + 1
    const resultTop = startTop + step * 2

    return `
      @media (min-width: ${minWith}px) and (max-width: ${maxWith}px) {
        top: ${resultTop}%;
      }
    `
  })}
  @media (max-width: 1599px) {
    top: 500px;
  }
  @media (max-width: 575px) {
    top: 100px;
  }
  @keyframes animateBubble {
    0% {
      margin-top: 600px;
    }
    100% {
      margin-top: -100%;
    }
  }
  @keyframes sideWays {
    0% {
      margin-left: 0px;
    }
    100% {
      margin-left: 50px;
    }
  }
  .x1 {
    animation: animateBubble 25s linear infinite,
      sideWays 2s ease-in-out infinite alternate;
    left: -5%;
    top: 5%;
    transform: scale(0.6);
  }
  .x2 {
    animation: animateBubble 20s linear infinite,
      sideWays 4s ease-in-out infinite alternate;
    left: 5%;
    top: 80%;
    transform: scale(0.4);
  }
  .x3 {
    animation: animateBubble 28s linear infinite,
      sideWays 2s ease-in-out infinite alternate;
    left: 10%;
    top: 40%;
    transform: scale(0.7);
  }
  .x4 {
    animation: animateBubble 22s linear infinite,
      sideWays 3s ease-in-out infinite alternate;
    left: 20%;
    top: 0;
    transform: scale(0.3);
  }
  .x5 {
    animation: animateBubble 29s linear infinite,
      sideWays 4s ease-in-out infinite alternate;
    left: 30%;
    top: 50%;
    transform: scale(0.5);
  }
  .x6 {
    animation: animateBubble 21s linear infinite,
      sideWays 2s ease-in-out infinite alternate;
    left: 50%;
    top: 0;
    transform: scale(0.8);
  }
  .x7 {
    animation: animateBubble 20s linear infinite,
      sideWays 2s ease-in-out infinite alternate;
    left: 65%;
    top: 70%;
    transform: scale(0.4);
  }

  .x8 {
    animation: animateBubble 22s linear infinite,
      sideWays 3s ease-in-out infinite alternate;
    left: 80%;
    top: 10%;
    transform: scale(0.3);
  }
  .x9 {
    animation: animateBubble 29s linear infinite,
      sideWays 4s ease-in-out infinite alternate;
    left: 90%;
    top: 50%;
    transform: scale(0.6);
  }
  .x10 {
    animation: animateBubble 26s linear infinite,
      sideWays 2s ease-in-out infinite alternate;
    left: 80%;
    top: 80%;
    transform: scale(0.3);
  }
  .x11 {
    animation: animateBubble 25s linear infinite,
      sideWays 2s ease-in-out infinite alternate;
    left: -5%;
    top: 5%;
    transform: scale(0.6);
  }
  .x12 {
    animation: animateBubble 20s linear infinite,
      sideWays 4s ease-in-out infinite alternate;
    left: 2.5%;
    top: 90%;
    transform: scale(0.4);
  }
  .x13 {
    animation: animateBubble 28s linear infinite,
      sideWays 2s ease-in-out infinite alternate;
    left: 5%;
    top: 35%;
    transform: scale(0.7);
  }
  .x14 {
    animation: animateBubble 22s linear infinite,
      sideWays 3s ease-in-out infinite alternate;
    left: 15%;
    top: 5%;
    transform: scale(0.3);
  }
  .x15 {
    animation: animateBubble 29s linear infinite,
      sideWays 4s ease-in-out infinite alternate;
    left: 25%;
    top: 40%;
    transform: scale(0.5);
  }
  .x16 {
    animation: animateBubble 21s linear infinite,
      sideWays 2s ease-in-out infinite alternate;
    left: 55%;
    top: 10%;
    transform: scale(0.8);
  }
  .x17 {
    animation: animateBubble 20s linear infinite,
      sideWays 2s ease-in-out infinite alternate;
    left: 75%;
    top: 80%;
    transform: scale(0.4);
  }
  .x18 {
    animation: animateBubble 22s linear infinite,
      sideWays 3s ease-in-out infinite alternate;
    left: 90%;
    top: 20%;
    transform: scale(0.3);
  }
  .x19 {
    animation: animateBubble 29s linear infinite,
      sideWays 4s ease-in-out infinite alternate;
    left: 45%;
    top: 70%;
    transform: scale(0.6);
  }
  .x20 {
    animation: animateBubble 26s linear infinite,
      sideWays 2s ease-in-out infinite alternate;
    left: 30%;
    top: 30%;
    transform: scale(0.3);
  }
  .x21 {
    animation: animateBubble 28s linear infinite,
      sideWays 2s ease-in-out infinite alternate;
    left: 70%;
    top: 50%;
    transform: scale(0.7);
  }
  .x22 {
    animation: animateBubble 22s linear infinite,
      sideWays 3s ease-in-out infinite alternate;
    left: 25%;
    top: 25%;
    transform: scale(0.6);
  }
  .x23 {
    animation: animateBubble 29s linear infinite,
      sideWays 4s ease-in-out infinite alternate;
    left: 65%;
    top: 90%;
    transform: scale(0.5);
  }
  .x24 {
    animation: animateBubble 21s linear infinite,
      sideWays 2s ease-in-out infinite alternate;
    left: 90%;
    top: 15%;
    transform: scale(0.8);
  }
  .x25 {
    animation: animateBubble 20s linear infinite,
      sideWays 2s ease-in-out infinite alternate;
    left: 55%;
    top: 46%;
    transform: scale(0.4);
  }
  .x26 {
    animation: animateBubble 22s linear infinite,
      sideWays 3s ease-in-out infinite alternate;
    left: 65%;
    top: 35%;
    transform: scale(0.3);
  }
  .x27 {
    animation: animateBubble 25s linear infinite,
      sideWays 2s ease-in-out infinite alternate;
    left: -50%;
    top: 30%;
    transform: scale(0.6);
  }
  .x28 {
    animation: animateBubble 20s linear infinite,
      sideWays 4s ease-in-out infinite alternate;
    left: 65%;
    top: 5%;
    transform: scale(0.4);
  }
  .x29 {
    animation: animateBubble 29s linear infinite,
      sideWays 4s ease-in-out infinite alternate;
    left: 45%;
    top: 10%;
    transform: scale(0.6);
  }
  .x30 {
    animation: animateBubble 26s linear infinite,
      sideWays 2s ease-in-out infinite alternate;
    left: 600%;
    top: 10%;
    transform: scale(0.4);
  }
  .bubble {
    border-radius: 50%;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1),
      inset 0px 5px 10px 2px rgba(255, 255, 255, 0.7);
    height: 16px;
    position: absolute;
    width: 16px;
  }
  .bubble:after {
    background: radial-gradient(
      ellipse at center,
      rgba(255, 255, 255, 0.3) 0%,
      rgba(255, 255, 255, 0) 70%
    );
    border-radius: 50%;
    box-shadow: inset 0 3px 5px rgba(255, 255, 255, 0.3);
    content: '';
    height: 14px;
    left: 2px;
    position: absolute;
    width: 14px;
  }
`
