import React, { useState } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import BackgroundImage from 'gatsby-background-image'
import styled from 'styled-components'
import { Container, Row, Col, Card, CardTitle, CardText } from 'reactstrap'
import { RoughNotation } from 'react-rough-notation'

import { Title, Button } from './elements'
import AdvantageItem from './advantage-card-item'
import AdvantageCarouselMobile from './advantage-carousel-mobile'
import Drawer from '../components/drawer'
import ModalSingle from './modal-single'

import advantagesWhyBorderBGImage from '../images/advantages_why_border_bg.svg'
import advantagesSearchBorderBGImage from '../images/advantages_search_border_bg.svg'
import advantagesWhyBGMobile from '../images/advantages_why_bg_mobile.svg'
import advantagesArrowCircleButtonIcon from '../images/arrow_circle_btn_icon.svg'

const EndInfo = styled.div`
  font-family: ${(props) => props.theme.fontSaaSeriesCD};
  position: relative;
  text-align: center;
  top: 50%;
  transform: translateY(-50%);

  ${Title} {
    color: #800609;
  }

  p {
    color: #a8bdcb;
    font-size: 42px;
    margin: 0 auto;
    max-width: 75%;

    @media (max-width: 499px) {
      font-size: 26px;
      max-width: 100%;
    }
  }
`

const WinnersList = styled.ul`
  list-style-type: none;
  margin: -2rem 0 -2rem 0;
  padding: 0;

  li {
    margin: 0 0 5px 0;

    span {
      display: inline-block;
      width: 45px;
    }
  }
`

const AdvantagesWrapper = styled.div``

const AdvantagesTopBGWrapper = styled(BackgroundImage)`
  background-position-y: top;
`

const AdvantagesGardenWrapper = styled.div`
  position: relative;
  padding-top: 200px;
  @media (min-width: 768px) and (max-width: 991px) {
    padding-top: 150px;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    padding-top: 120px;
  }
  @media (max-width: 575px) {
    padding-top: 10px;
  }
`

const AdvantagesGardenTitle = styled(Title)`
  color: #a8bdcb;
  margin-bottom: 35px;
  @media (min-width: 768px) and (max-width: 991px) {
    margin-bottom: 25px;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    margin-bottom: 20px;
  }
`

const AdvantagesGardenDesc = styled(Col)`
  padding-top: 100px;
  @media (min-width: 768px) and (max-width: 991px) {
    padding-top: 35px;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    padding-top: 25px;
  }
  @media (max-width: 575px) {
    padding-left: 20px;
    padding-right: 20px;
  }
  p {
    margin-bottom: 35px;
    @media (min-width: 768px) and (max-width: 991px) {
      margin-bottom: 25px;
    }
    @media (min-width: 576px) and (max-width: 767px) {
      margin-bottom: 20px;
    }
    span {
      position: relative;
      color: ${(props) => props.theme.colorWhite};
      line-height: 1.3;
      margin-bottom: 6px;
      z-index: 10;
    }
  }
`

const AdvantagesGardenImage = styled(Col)`
  @media (min-width: 576px) and (max-width: 767px) {
    padding-left: 17%;
    padding-right: 17%;
  }
  @media (max-width: 575px) {
    padding-left: 10%;
    padding-right: 10%;
  }

  img {
    height: auto;
    width: 100%;
  }
`

const AdvantagesWhyWrapper = styled.div``

const AdvantagesWhyWrapperBG = styled(BackgroundImage)`
  background-position: bottom center;
  background-size: contain;
  @media (min-width: 576px) {
    background-size: cover;
    margin-left: 40px;
    margin-right: 40px;
  }
  &:before {
    @media (max-width: 575px) {
      content: none !important;
    }
  }
`

const AdvantagesWhyTitle = styled(Title)`
  color: #85764e;
  font-size: 90px;
  text-align: center;
  line-height: 1;
  margin-top: 150px;
  @media (min-width: 768px) and (max-width: 991px) {
    margin-top: 100px;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    margin-top: 180px;
  }
  @media (max-width: 575px) {
    margin-top: 220px;
  }
`

const AdvantagesWhyCardsWrapper = styled(Row)`
  display: flex;
  justify-content: center;
  margin-top: 70px;
  @media (max-width: 575px) {
    margin-top: 0;
  }
`

const AdvantagesWhyCardsWrapperDesktop = styled(AdvantagesWhyCardsWrapper)`
  @media (max-width: 575px) {
    display: none;
  }
`

const AdvantagesWhyCardsWrapperMobile = styled(AdvantagesWhyCardsWrapper)`
  background-image: url(${advantagesWhyBGMobile});
  background-repeat: no-repeat;
  background-position: bottom center;
  background-size: cover;
  @media (min-width: 576px) {
    display: none;
  }
`

const AdvantagesWhyCardWrapper = styled(Col)`
  margin-bottom: 70px;
`

const AdvantagesWhyCard = styled(Card)`
  position: relative;
  height: 100%;
  background-color: #f1ebdf;
  padding: 40px;
  border: none;
  border-radius: 0;
  box-shadow: 0px 33px 54px 0px rgba(111, 81, 49, 0.17);
  &:before {
    content: '';
    position: absolute;
    top: 10px;
    left: 10px;
    right: 10px;
    bottom: 10px;
    background-image: url(${advantagesWhyBorderBGImage});
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100% 100%;
    z-index: 10;
  }
`

const AdvantagesWhyCardNumber = styled.span`
  position: absolute;
  top: -35px;
  left: 48px;
  font-size: 100px;
  font-style: italic;
  line-height: 0.32;
  z-index: 20;
`

const AdvantagesWhyCardTitle = styled(CardTitle)`
  position: relative;
  color: #800609;
  font-family: ${(props) => props.theme.fontSaaSeriesCD};
  font-size: 26px;
  font-weight: 400;
  margin-bottom: 30px;
  z-index: 20;
`

const AdvantagesWhyCardText = styled(CardText)`
  position: relative;
  margin-bottom: 20px;
  z-index: 20;
`

const AdvantagesSearchWrapper = styled.div`
  padding-top: 20px;
`

const AdvantagesSearchBGWrapper = styled(BackgroundImage)`
  background-position-y: top;
  padding-top: 170px;
  @media (min-width: 768px) and (max-width: 991px) {
    padding-top: 120px;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    padding-top: 100px;
  }
  @media (max-width: 575px) {
    padding-top: 50px;
  }
`

const AdvantagesSearchRowWrapper = styled(Row)`
  display: flex;
`

const AdvantagesSearchDesc = styled(AdvantagesGardenDesc)`
  @media (max-width: 575px) {
    padding-top: 10px;
  }
`

const AdvantagesSearchImage = styled(Col)`
  @media (max-width: 575px) {
    order: -2;
  }
`

const AdvantagesSearchTitle = styled(AdvantagesGardenTitle)`
  line-height: 0.9;
`

const AdvantagesSearchCardsWrapper = styled(AdvantagesWhyCardsWrapper)`
  display: flex;
  justify-content: center;
  margin-top: 70px;
`

const AdvantagesSearchCardWrapper = styled(AdvantagesWhyCardWrapper)`
  margin-bottom: 70px;
`

const AdvantagesSearchCard = styled(AdvantagesWhyCard)`
  background-color: transparent;
  box-shadow: none;
  &:before {
    top: 5px;
    left: 5px;
    right: 5px;
    bottom: 5px;
    background-image: url(${advantagesSearchBorderBGImage});
    background-size: 100% 100%;
  }
`

const AdvantagesSearchCardNumber = styled(AdvantagesWhyCardNumber)``

const AdvantagesSearchCardTitle = styled(AdvantagesWhyCardTitle)``

const AdvantagesSearchCardText = styled(AdvantagesWhyCardText)``

const AdvantagesFormDesc = styled(AdvantagesGardenDesc)`
  padding-top: 0;

  a {
    color: ${(props) => props.theme.textColor};
    text-decoration: underline;
  }
`

const AdvantagesFormColWrapper = styled(Col)`
  @media (max-width: 575px) {
    padding-left: 20px;
    padding-right: 20px;
  }
`

const AdvantagesDrawerButton = styled.div`
  position: absolute;
  top: 50%;
  right: 5%;
  width: 144px;
  height: 144px;
  background-image: url(${advantagesArrowCircleButtonIcon});
  background-repeat: no-repeat;
  background-position: center right;
  background-size: contain;
  color: ${(props) => props.theme.colorBlack};
  font-family: ${(props) => props.theme.fontSaaSeriesCD};
  font-size: 18px;
  text-align: center;
  padding-top: 144px;
  transform: translateY(-80%);
  transition: opacity 0.3s ease;
  z-index: 100;
  @media (max-width: 1199px) {
    right: 2%;
    width: 120px;
    height: 120px;
    padding-top: 120px;
  }
  @media (max-width: 767px) {
    top: auto;
    left: 50%;
    right: auto;
    bottom: 0;
    font-size: 20px;
    transform: translate(-50%, 50%);
  }
  cursor: pointer;
  &:hover {
    opacity: 0.98;
  }
  span {
    display: block;
    padding-top: 12px;
    @media (max-width: 767px) {
      padding-top: 5px;
    }
  }
`

const Advantages = () => {
  const data = useStaticQuery(graphql`
    query {
      advantagesTopBGImage: file(relativePath: { eq: "advantages_bg.png" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      advantagesWhyBGImage: file(
        relativePath: { eq: "advantages_why_bg.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1024) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      advantagesSearchBGImage: file(
        relativePath: { eq: "advantages_search_bg.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      advantagesSearchEwerImage: file(
        relativePath: { eq: "advantages_search_ewer.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }

      advantagesSlideImage1: file(
        relativePath: { eq: "advantages_slide_1.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      advantagesSlideImage2: file(
        relativePath: { eq: "advantages_slide_2.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      advantagesSlideImage3: file(
        relativePath: { eq: "advantages_slide_3.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      advantagesSlideImage4: file(
        relativePath: { eq: "advantages_slide_4.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      advantagesSlideImage5: file(
        relativePath: { eq: "advantages_slide_5.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  `)

  const advantagesList = [
    {
      title: `Zbiera i oczyszcza<br /> deszczówkę`,
      shortText: `Beton tu, beton tam. Nic dziwnego, że wodzie coraz trudniej jest wsiąkać.`,
      text: `<p>Beton tu, beton tam. Nic dziwnego, że wodzie coraz trudniej jest wsiąkać w podłoże. Ogrody deszczowe oczyszczają i zbierają deszczówkę, dzięki czemu nie spływa ona od razu do kanalizacji, czy rzeki tylko jest zatrzymywana w glebie, a tym samym pomaga dbać o rośliny w trakcie suszy. Zapobiega przy tym obniżaniu poziomu wód gruntowych, a nawet lokalnym podtopieniom w trakcie ulewnych opadów. Ponadto nawilża i delikatnie ochładza powietrze w najbliższym otoczeniu.,. Zawsze warto też dbać o okoliczne parki, tereny zielone, zadrzewione skwery, których istnienie w mieście pomaga jego mieszkańcom, oczyszczając powietrze, nawilżając je, ale również zapobiegając podtopieniom i dając wytchnienie oczom i miejsce na spacer.</p>`,
    },
    {
      title: `Lubi miasta<br />i przydomowe ogrody`,
      shortText: `Wszędzie dobrze, ale… Nie ma żadnego „ale”.`,
      text: `<p>Ogród deszczowy to taki druh, który doskonale czuje się jako element zielonej infrastruktury miasta, ale też lubi się rozgościć w przydomowym ogrodzie. Można nadawać mu dowolne kształty i formy. Wystarczy trzymać się kilku prostych zasad, wybrać odpowiednią roślinność i później czekać na deszcz, np. popijając Kasztelana ;)</p>`,
    },
    {
      title: `Poprawia <br /> bioróżnorodność`,
      shortText: `Tym co dobre trzeba się dzielić! A chętnych nie brakuje.`,
      text: `<p>Tym, co dobre trzeba się dzielić! A chętnych nie brakuje. Ogrody deszczowe tworzą idealne siedliska dla ptaków, motyli, pszczół i innych owadów, wspomagając w ten sposób zachowanie bioróżnorodności. Wszak i nasi mali druhowie potrzebują miejsca na owadzie biesiady.</p>`,
    },
    {
      title: `Jest łatwy<br /> w pielęgnacji`,
      shortText: `O tym, że jest funkcjonalny, Waść już wiesz. Ale urodę też ma nie byle jaką.`,
      text: `<p>O tym, że jest funkcjonalny, Waść już wiesz, ale urodę też ma nie byle jaką. A jak coś jest ładne, to miło oko zawiesić i wspólnie się pozachwycać. Nie dziwota, że ogród deszczowy staje się często miejscem spotkań mieszkańców, którzy szukają wytchnienia od upałów lub po prostu chcą spędzić czas w miłym (i ładnym – wspominaliśmy już?) otoczeniu.</p>`,
    },
    {
      title: `Jest ładny!<br /> &nbsp;`,
      shortText: `Czy Waszmość ma na sumieniu jakieś rośliny? Każdemu się zdarza.`,
      text: `<p>Czy Waszmość ma na sumieniu jakieś rośliny? Każdemu się zdarza. Na szczęście ogrody deszczowe są niemal w 100% samowystarczalne. Nie wymagają podlewania (oprócz okresów suszy) ani nawożenia. Ładne i funkcjonalne – takie połączenia hołubimy!</p>`,
    },
  ]

  const [isShowDrawer, setShowDrawer] = useState(false)
  const [showWinners, setShowWinners] = useState(false)

  const toggleDrawer = () => {
    const elem = document.querySelector('body')

    elem.classList.toggle('drawer')
    setShowDrawer(!isShowDrawer)
  }

  return (
    <>
      <AdvantagesWrapper>
        <AdvantagesTopBGWrapper
          Tag="div"
          className="waterTopBGImage"
          fluid={data.advantagesTopBGImage.childImageSharp.fluid}
          backgroundColor={`transparent`}
        >
          <AdvantagesGardenWrapper id="gardens">
            <Container>
              <Row>
                <AdvantagesGardenDesc xs={12} md={6}>
                  <AdvantagesGardenTitle>
                    Ogrody deszczowe
                  </AdvantagesGardenTitle>
                  <p>
                    Wygląda jak zwykły ogród. Czasami ma{'\u00A0'}rozmach, a
                    {'\u00A0'}czasem wielkość skrzynki.{' '}
                    <RoughNotation
                      show={true}
                      color="#becbd3"
                      animate={false}
                      padding={2}
                      type="highlight"
                      multiline={true}
                    >
                      Jednak nie rozmiar się liczy, lecz wnętrze.
                    </RoughNotation>
                  </p>
                  <p>
                    Ogród deszczowy składa się głównie z gatunków roślin
                    podmokłych łąk, które nie tylko ucieszą oko, ale też zbiorą
                    i{'\u00A0'}oczyszczą deszczówkę, np. z{'\u00A0'}dachów i
                    {'\u00A0'}chodników.
                  </p>
                  <p>
                    A działając lokalnie, wspieramy ochronę zasobów wodnych w
                    {'\u00A0'}
                    całym kraju.{' '}
                    <RoughNotation
                      show={true}
                      color="#becbd3"
                      animate={false}
                      padding={2}
                      type="highlight"
                      multiline={true}
                    >
                      Dlatego postanowiliśmy, że{'\u00A0'}w{'\u00A0'}Sierpcu
                      powstanie… ogród deszczowy!
                    </RoughNotation>
                  </p>
                </AdvantagesGardenDesc>
                <AdvantagesGardenImage>
                  <img
                    src={require('../images/advantages_rain_garden.gif')}
                    alt="Ogród deszczowy"
                  />
                </AdvantagesGardenImage>
              </Row>
            </Container>
            <AdvantagesDrawerButton onClick={() => toggleDrawer()}>
              <span>
                zobacz
                <br /> jak to działa
              </span>
            </AdvantagesDrawerButton>
          </AdvantagesGardenWrapper>
          <AdvantagesWhyWrapper>
            <AdvantagesWhyWrapperBG
              Tag="div"
              className="advantagesWhyBGImage"
              fluid={data.advantagesWhyBGImage.childImageSharp.fluid}
              backgroundColor={`transparent`}
            >
              <Container>
                <Row>
                  <Col>
                    <AdvantagesWhyTitle>
                      Dlaczego
                      <br /> warto?
                    </AdvantagesWhyTitle>
                  </Col>
                </Row>
                <AdvantagesWhyCardsWrapperDesktop>
                  {advantagesList.map((_, index) => {
                    return (
                      <AdvantageItem
                        type="adwantage"
                        index={index}
                        items={advantagesList}
                        images={data}
                        key={index}
                      />
                    )
                  })}
                </AdvantagesWhyCardsWrapperDesktop>
                <AdvantagesWhyCardsWrapperMobile>
                  <AdvantageCarouselMobile
                    type="adwantage"
                    listItems={advantagesList}
                    images={data}
                  />
                </AdvantagesWhyCardsWrapperMobile>
              </Container>
            </AdvantagesWhyWrapperBG>
          </AdvantagesWhyWrapper>
          <AdvantagesSearchWrapper id="rainwater">
            <AdvantagesSearchBGWrapper
              Tag="div"
              className="advantagesSearchBG"
              fluid={data.advantagesSearchBGImage.childImageSharp.fluid}
              backgroundColor={`transparent`}
            >
              <Container>
                <AdvantagesSearchRowWrapper>
                  <AdvantagesSearchDesc xs={12} md={6}>
                    <AdvantagesSearchTitle>deszczówka</AdvantagesSearchTitle>
                    <p>
                      Jeśli masz Waszmość w sobie cechy zdobywcy, to{'\u00A0'}z
                      {'\u00A0'}pewnością ucieszy Cię wieść, że możesz je
                      {'\u00A0'}wykorzystać w{'\u00A0'}słusznym celu. Jak?{' '}
                      <RoughNotation
                        show={true}
                        color="#b1c0cb"
                        animate={false}
                        padding={2}
                        type="highlight"
                        multiline={true}
                      >
                        Łapiąc deszczówkę.
                      </RoughNotation>{' '}
                      To inny, obok ogrodów deszczowych, sposób{'\u00A0'}na
                      {'\u00A0'}to, aby efektywnie gospodarzyć zasobami wody.
                      Zatem na{'\u00A0'}deszczówkę miej baczenie i{'\u00A0'}racz
                      ją{'\u00A0'}łapać jak najczęściej. A{'\u00A0'}i{'\u00A0'}w
                      naszym konkursie udział weź. Byle rychło!{' '}
                    </p>
                  </AdvantagesSearchDesc>
                  <AdvantagesSearchImage>
                    <Img
                      fluid={
                        data.advantagesSearchEwerImage.childImageSharp.fluid
                      }
                    />
                  </AdvantagesSearchImage>
                </AdvantagesSearchRowWrapper>
                {false && (
                  <AdvantagesSearchCardsWrapper>
                    <AdvantagesSearchCardWrapper sm="4">
                      <AdvantagesSearchCard body>
                        <AdvantagesSearchCardNumber>
                          1.
                        </AdvantagesSearchCardNumber>
                        <AdvantagesSearchCardTitle as="h3">
                          Lorem ipsum
                          <br /> dolor
                        </AdvantagesSearchCardTitle>
                        <AdvantagesSearchCardText>
                          LLorem ipsum dolor sit amet, consectetur adipiscing
                          elit. Nunc sit amet convallis
                        </AdvantagesSearchCardText>
                      </AdvantagesSearchCard>
                    </AdvantagesSearchCardWrapper>
                    <AdvantagesSearchCardWrapper sm="4">
                      <AdvantagesSearchCard body>
                        <AdvantagesSearchCardNumber>
                          2.
                        </AdvantagesSearchCardNumber>
                        <AdvantagesSearchCardTitle as="h3">
                          Donec luctus turpis
                          <br />{' '}
                        </AdvantagesSearchCardTitle>
                        <AdvantagesSearchCardText>
                          Donec luctus turpis et finibus accumsan. Quisque in
                          lectus odio
                        </AdvantagesSearchCardText>
                      </AdvantagesSearchCard>
                    </AdvantagesSearchCardWrapper>
                    <AdvantagesSearchCardWrapper sm="4">
                      <AdvantagesSearchCard body>
                        <AdvantagesSearchCardNumber>
                          3.
                        </AdvantagesSearchCardNumber>
                        <AdvantagesSearchCardTitle as="h3">
                          Aenean consectetur
                          <br /> orci id
                        </AdvantagesSearchCardTitle>
                        <AdvantagesSearchCardText>
                          Aenean consectetur orci id purus tincidunt rutrum.
                        </AdvantagesSearchCardText>
                      </AdvantagesSearchCard>
                    </AdvantagesSearchCardWrapper>
                  </AdvantagesSearchCardsWrapper>
                )}
                <Row id="contest">
                  <AdvantagesFormDesc xs={12} lg={6}>
                    <AdvantagesSearchTitle>
                      KONKURS!
                      <br /> Deszczówkę Racz Waść łapać!
                    </AdvantagesSearchTitle>
                    <p>
                      W{'\u00A0'}dawnych czasach zadaniem Kasztelana było dbać o
                      {'\u00A0'}region, który był pod jego zarządem. Teraz Ty,{' '}
                      Waść zatroszcz się o{'\u00A0'}zasoby wodne w{'\u00A0'}
                      okolicy i{'\u00A0'}zachęć do{'\u00A0'}tego innych.
                      Odpowiedz na{'\u00A0'}pytanie, dlaczego woda jest ważnym
                      zasobem i{'\u00A0'}należy jej z{'\u00A0'}umiarem używać.
                      Tylko bez lania wody, bo{'\u00A0'}limit to{'\u00A0'} 500
                      znaków ze
                      {'\u00A0'}spacjami. Ni{'\u00A0'}mniej, ni{'\u00A0'}więcej.
                    </p>
                    <p>
                      <strong>
                        30{'\u00A0'}najciekawszych i{'\u00A0'}najbardziej
                        oryginalnych prac zostanie nagrodzonych stągwiami poj.
                        210l. do{'\u00A0'}zbiórki wody deszczowej z{'\u00A0'}
                        systemem przyłączeniowym.
                      </strong>
                    </p>

                    <p>
                      <strong>
                        <a
                          href="/regulamin-konkursu.pdf"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Regulamin konkursu
                        </a>
                      </strong>
                    </p>
                  </AdvantagesFormDesc>
                  <AdvantagesFormColWrapper xs={12} lg={6}>
                    <EndInfo>
                      <Title>Konkurs zakończony.</Title>
                      <Button onClick={() => setShowWinners(!showWinners)}>
                        Poznaj listę zwycięzców
                      </Button>
                    </EndInfo>
                  </AdvantagesFormColWrapper>
                </Row>
              </Container>
            </AdvantagesSearchBGWrapper>
          </AdvantagesSearchWrapper>
        </AdvantagesTopBGWrapper>
      </AdvantagesWrapper>
      <Drawer show={isShowDrawer} toggleDrawer={toggleDrawer} />
      {showWinners && (
        <ModalSingle
          isShow={true}
          item={{
            title: 'Lista zwycięzców',
            text: (
              <WinnersList>
                <li>
                  <span>1.</span> Natalia Leśniewska
                </li>
                <li>
                  <span>2.</span> Ewa Preuss
                </li>
                <li>
                  <span>3.</span> Danuta Choczyńska
                </li>
                <li>
                  <span>4.</span> Karolina Klekowicka
                </li>
                <li>
                  <span>5.</span> Natalia Tylmanowska
                </li>
                <li>
                  <span>6.</span> Agnieszka Laskowska
                </li>
                <li>
                  <span>7.</span> Anna Gurtowska
                </li>
                <li>
                  <span>8.</span> Łukasz Figurski
                </li>
                <li>
                  <span>9.</span> Monika Szulc
                </li>
                <li>
                  <span>10.</span> Karol Rydzeński
                </li>
                <li>
                  <span>11.</span> Iwona Jeżewska
                </li>
                <li>
                  <span>12.</span> Monika Rosiak
                </li>
                <li>
                  <span>13.</span> Agnieszka Świątkowska
                </li>
                <li>
                  <span>14.</span> Paweł Staniszewski
                </li>
                <li>
                  <span>15.</span> Anna Ostrowska
                </li>
                <li>
                  <span>16.</span> Ewa Wojciechowicz
                </li>
                <li>
                  <span>17.</span> Agnieszka Adryjańczyk
                </li>
                <li>
                  <span>18.</span> Adam Kamiński
                </li>
                <li>
                  <span>19.</span> Anna Motylewska
                </li>
                <li>
                  <span>20.</span> Kamila Cieślak
                </li>
                <li>
                  <span>21.</span> Beata Raduj
                </li>
                <li>
                  <span>22.</span> Mirosław Ciepłuch
                </li>
                <li>
                  <span>23.</span> Paulina Karowska
                </li>
                <li>
                  <span>24.</span> Elżbieta Jabłońska
                </li>
                <li>
                  <span>25.</span> Kinga Kucińska
                </li>
                <li>
                  <span>26.</span> Katarzyna Karpińska
                </li>
                <li>
                  <span>27.</span> Małgorzata Lewandowska
                </li>
                <li>
                  <span>28.</span> Ewelina Krajewska
                </li>
                <li>
                  <span>29.</span> Patrycja Dąbrowska
                </li>
                <li>
                  <span>30.</span> Tomasz Piasecki
                </li>
              </WinnersList>
            ),
          }}
          handleToggle={() => setShowWinners(!showWinners)}
        />
      )}
    </>
  )
}

export default Advantages
