import React, { useState } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import BackgroundImage from 'gatsby-background-image'
import Img from 'gatsby-image'
import styled from 'styled-components'
import { Col, Row, Container } from 'reactstrap'

import ModalSlidesSimple from './modal-slides-simple'
import { Title, Button, ContainerPaddingsMixin } from './elements'

const OtherInitiativesWrapper = styled.div`
  ${ContainerPaddingsMixin}
  @media (max-width: 991px) {
    margin-top: 20px;
  }
  @media (max-width: 575px) {
    padding-left: 20px;
    padding-right: 20px;
  }
`

const OtherInitiativesBGWrapper = styled(BackgroundImage)`
  background-position-y: top;
  padding-top: 140px;
  padding-bottom: 160px;
  @media (max-width: 991px) {
    padding-bottom: 0;
  }
  @media (max-width: 575px) {
    padding-top: 70px;
  }
`

const OtherInitiativesTitle = styled(Title)`
  font-size: 48px;
  text-align: center;
  line-height: 1;
  margin-bottom: 50px;
`

const OtherInitiativesItemWrapper = styled(Col)`
  position: relative;
`

const OtherInitiativesItemTitle = styled(Title)`
  font-size: 26px;
  margin-top: -5%;
  span {
    font-family: ${(props) => props.theme.fontPlayfairDisplay};
    font-size: 100px;
    font-style: italic;
  }
`

const OtherInitiativesItemDescWrapper = styled.div`
  color: ${(props) => props.theme.colorWhite};
  @media (max-width: 991px) {
    position: relative;
    margin-bottom: 40px;
  }
  @media (max-width: 575px) {
    padding-bottom: 90px;
    margin-bottom: 0;
  }
  p {
    color: #e4e6df;
    padding-left: 55px;
    padding-right: 190px;
    @media (max-width: 575px) {
      padding-right: 55px;
    }
  }
`

const OtherInitiativesButton = styled(Button)`
  position: absolute;
  right: 0;
  bottom: 0;
  @media (max-width: 575px) {
    right: 50%;
    bottom: 30px;
    transform: translateX(50%);
  }
`

const OtherInitiatives = (props) => {
  const data = useStaticQuery(graphql`
    query {
      otherInitiativesBGImage: file(
        relativePath: { eq: "other_initiatives_bg.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      otherInitiativesImage1: file(
        relativePath: { eq: "other_initiatives_image_1.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 640) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      otherInitiativesImage2: file(
        relativePath: { eq: "other_initiatives_image_2.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 640) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      otherInitiativesInfographic1: file(
        relativePath: { eq: "other_initiatives/iniciative_infographic1.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 640) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      otherInitiativesInfographic2: file(
        relativePath: { eq: "other_initiatives/iniciative_infographic2.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 640) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      otherInitiativesInfographic3: file(
        relativePath: { eq: "other_initiatives/iniciative_infographic3.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 640) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      otherInitiativesInfographic4: file(
        relativePath: { eq: "other_initiatives/iniciative_infographic4.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 640) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      otherInitiativesInfographic5: file(
        relativePath: { eq: "other_initiatives/iniciative_infographic5.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 640) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      otherInitiativesCapacity1: file(
        relativePath: { eq: "other_initiatives/iniciative_capacity1.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 640) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      otherInitiativesCapacity2: file(
        relativePath: { eq: "other_initiatives/iniciative_capacity2.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 640) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      otherInitiativesCapacity3: file(
        relativePath: { eq: "other_initiatives/iniciative_capacity3.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 640) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      otherInitiativesCapacity4: file(
        relativePath: { eq: "other_initiatives/iniciative_capacity4.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 640) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  `)

  const slides1Items = [
    {
      title: `Ogród deszczowy`,
      text: `<p>Gdy susza na horyzoncie, Kasztelan z założonymi rękami nie czeka! By zapobiegać jej skutkom, w ramach programu „Pieczą Otaczamy" stworzyliśmy w Sierpcu ogród deszczowy. Zielona inwestycja powstała na skwerze przy budynku zabytkowego Ratusza. Ogród skutecznie gromadzi i filtruje wodę opadową, spływającą do niego z pobliskich dachów. Czym jest susza? Jak jej przeciwdziałać? Co nam dają ogrody deszczowe? Dowiedzcie się więcej – sprawdźcie nasze infografiki i materiał wideo!</p>`,
      image: null,
      video: null,
    },
    {
      title: null,
      text: null,
      image: (
        <Img fluid={data.otherInitiativesInfographic1.childImageSharp.fluid} />
      ),
      video: null,
    },
    {
      title: null,
      text: null,
      image: (
        <Img fluid={data.otherInitiativesInfographic2.childImageSharp.fluid} />
      ),
      video: null,
    },
    {
      title: null,
      text: null,
      image: (
        <Img fluid={data.otherInitiativesInfographic3.childImageSharp.fluid} />
      ),
      video: null,
    },
    {
      title: null,
      text: null,
      image: (
        <Img fluid={data.otherInitiativesInfographic4.childImageSharp.fluid} />
      ),
      video: null,
    },
    {
      title: null,
      text: null,
      image: (
        <Img fluid={data.otherInitiativesInfographic5.childImageSharp.fluid} />
      ),
      video: null,
    },
    {
      title: null,
      text: null,
      image: null,
      video: '/video/Kasztelan_v3',
    },
  ]

  const slides2Items = [
    {
      title: `Stągwie na deszczówkę`,
      text: `<p>Racz Waść wiedzieć, że aż 97 proc. wody w Polsce pochodzi z opadów atmosferycznych. Dlatego jednym ze sposobów na przeciwdziałanie suszy jest wykorzystywanie wody deszczowej. By promować jej zbieranie i wykorzystywanie np. do podlewania roślin, przekazane przez markę Kasztelan stągwie stanęły przy budynkach użyteczności publicznej w Sierpcu. Możecie je zobaczyć np. przy Urzędzie Miasta. Stągiew zainstalowano również na terenie browaru. Dzięki konkursowi „Deszczówkę Waść Łap” zbiorniki na deszczówkę trafiły do ogródków 30 laureatów.</p>`,
      image: null,
      video: null,
    },
    {
      title: null,
      text: null,
      image: (
        <Img fluid={data.otherInitiativesCapacity1.childImageSharp.fluid} />
      ),
      video: null,
    },
    {
      title: null,
      text: null,
      image: (
        <Img fluid={data.otherInitiativesCapacity2.childImageSharp.fluid} />
      ),
      video: null,
    },
    {
      title: null,
      text: null,
      image: (
        <Img fluid={data.otherInitiativesCapacity3.childImageSharp.fluid} />
      ),
      video: null,
    },
    {
      title: null,
      text: null,
      image: (
        <Img fluid={data.otherInitiativesCapacity4.childImageSharp.fluid} />
      ),
      video: null,
    },
  ]

  const [slides1ModalOpen, setSlides1ModalOpen] = useState(false)
  const [slides2ModalOpen, setSlides2ModalOpen] = useState(false)

  const handleSlides1ModalClose = () => {
    setSlides1ModalOpen(false)
  }

  const handleSlides2ModalClose = () => {
    setSlides2ModalOpen(false)
  }

  return (
    <>
      <ModalSlidesSimple
        allItems={slides1Items}
        isOpen={slides1ModalOpen}
        onClose={handleSlides1ModalClose}
      />
      <ModalSlidesSimple
        allItems={slides2Items}
        isOpen={slides2ModalOpen}
        onClose={handleSlides2ModalClose}
      />
      <OtherInitiativesWrapper>
        <OtherInitiativesBGWrapper
          Tag="div"
          className="otherInitiativesBGImage"
          fluid={data.otherInitiativesBGImage.childImageSharp.fluid}
          backgroundColor={`#32671c`}
        >
          <Container>
            <Row>
              <Col xs={12}>
                <OtherInitiativesTitle>
                  Nasze pozostałe
                  <br /> inicjatywy
                </OtherInitiativesTitle>
              </Col>
              <OtherInitiativesItemWrapper xs={12} lg={6}>
                <div>
                  <Img
                    fluid={data.otherInitiativesImage1.childImageSharp.fluid}
                  />
                </div>
                <OtherInitiativesItemDescWrapper>
                  <OtherInitiativesItemTitle>
                    <span>1.</span>Ogród deszczowy
                  </OtherInitiativesItemTitle>
                  <p>
                    Taki ogród to piękny sposób na walkę z suszą. Sprawdź Waść
                    dlaczego i obejrzyj ogród deszczowy, który powstał w
                    Sierpcu!
                  </p>
                  <OtherInitiativesButton
                    onClick={() => setSlides1ModalOpen(true)}
                  >
                    więcej
                  </OtherInitiativesButton>
                </OtherInitiativesItemDescWrapper>
              </OtherInitiativesItemWrapper>
              <OtherInitiativesItemWrapper xs={12} lg={6}>
                <div>
                  <Img
                    fluid={data.otherInitiativesImage2.childImageSharp.fluid}
                  />
                </div>
                <OtherInitiativesItemDescWrapper>
                  <OtherInitiativesItemTitle>
                    <span>2.</span>Stągwie na deszczówkę
                  </OtherInitiativesItemTitle>
                  <p>Największy skarb potrafi spaść z nieba!</p>
                  <OtherInitiativesButton
                    onClick={() => setSlides2ModalOpen(true)}
                  >
                    więcej
                  </OtherInitiativesButton>
                </OtherInitiativesItemDescWrapper>
              </OtherInitiativesItemWrapper>
            </Row>
          </Container>
        </OtherInitiativesBGWrapper>
      </OtherInitiativesWrapper>
    </>
  )
}

export default OtherInitiatives
