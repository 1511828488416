import React, { useState } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import BackgroundImage from 'gatsby-background-image'
import styled from 'styled-components'
import { Container, Row, Col } from 'reactstrap'
import { RoughNotation } from 'react-rough-notation'

import {
  Title,
  Button,
  WithBubblesWrapper,
  ContainerPaddingsMixin,
} from './elements'
import ModalSlides from './modal-slides'

import waterGardenBGImage from '../images/water_garden_bg.svg'
import waterGardenBottomBGImage from '../images/water_garden_bottom_bg.svg'
import waterTopBGMobileImage from '../images/water_bg_mobile.svg'

const WaterWrapper = styled.div`
  overflow: hidden;
  position: relative;
  z-index: 10;
`

const WaterTopBGWrapper = styled(BackgroundImage)`
  background-position-y: top;
  &:before {
    @media (max-width: 575px) {
      background-image: none !important;
    }
  }
`

const WaterColWrapper = styled(Col)`
  padding-top: 600px;
  padding-bottom: 180px;
  @media (max-width: 575px) {
    background-image: url(${waterTopBGMobileImage});
    background-repeat: no-repeat;
    background-position: top center;
    background-size: cover;
    padding-top: 100px;
    padding-bottom: 0;
    margin-top: 50px;
  }
`

const WaterTitle = styled(Title)`
  text-align: center;
  line-height: 0.9;
  margin-bottom: 50px;
  @media (min-width: 768px) and (max-width: 991px) {
    font-size: 36px;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    font-size: 36px;
  }
  span {
    @media (max-width: 575px) {
      display: block;
      line-height: 1.2;
    }
  }
`

const WaterTextWrapper = styled.div`
  color: ${(props) => props.theme.colorWhite};
  text-align: center;
  @media (min-width: 768px) {
    padding-left: 25%;
    padding-right: 25%;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    padding-left: 15%;
    padding-right: 15%;
  }
  @media (max-width: 575px) {
    padding-left: 10px;
    padding-right: 10px;
  }
`

const WaterLinkWrapper = styled.div`
  @media (max-width: 575px) {
    text-align: center;
    margin-bottom: 50px;
  }
`

const WaterLink = styled(Button)`
  position: relative;
  margin-top: 40px;
  margin-bottom: 50px;
  z-index: 100;
  @media (min-width: 768px) and (max-width: 991px) {
    margin-top: 25px;
    margin-bottom: 35px;
  }
  @media (max-width: 575px) {
    ${(props) =>
      props.type === 'why' &&
      `
        margin-top: 5px;
      `};
  }
  &:before {
    content: '';
    position: absolute;
    left: 50%;
    bottom: -50px;
    display: block;
    width: 50px;
    height: 4px;
    background-color: #85764e;
    transform: translateX(-50%);
    z-index: 20;
    @media (max-width: 991px) {
      ${(props) =>
        props.type === 'why' &&
        `
        content: none;
      `};
    }
  }
  span {
    display: inline-block;
  }
`

const WaterBottomBGWrapper = styled(BackgroundImage)`
  background-position-y: top;
  /* margin-top: -70px; */
`

const WaterContainerBottom = styled(Container)`
  ${ContainerPaddingsMixin}
  @media (max-width: 575px) {
    padding-left: 0;
    padding-right: 0;
  }
`

const WaterCard = styled(Col)`
  margin-top: -70px;
  @media (max-width: 575px) {
    margin-top: 0;
  }
  &:last-of-type {
    @media (min-width: 576px) and (max-width: 767px) {
      margin-top: 30px;
    }
    @media (max-width: 575px) {
      padding-left: 40px;
      padding-right: 40px;
      margin-top: -50px;
    }
  }
`

const WaterCardWrapper = styled.div`
  height: 100%;
  ${(props) =>
    props.align === 'left' &&
    `
    padding-left: 35%;
    padding-right: 15px;
  `}
  ${(props) =>
    props.align === 'right' &&
    `
    padding-left: 15px;
    padding-right: 35%;
  `}
  ${(props) =>
    props.type === 'why' &&
    `
    color: ${props.theme.colorWhite};
  `}
  ${(props) =>
    props.type === 'map' &&
    `
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
    font-size: 14px;
    padding: 20px;
  `}
  @media (min-width: 992px) and (max-width: 1199px) {
    ${(props) =>
      props.align === 'left' &&
      `
      padding-left: 20%;
    `}
  }
  @media (max-width: 991px) {
    ${(props) =>
      props.align === 'left' &&
      `
      padding-left: 20px;
      padding-right: 20px;
    `}
  }
  @media (max-width: 575px) {
    ${(props) =>
      props.type === 'why' &&
      `
        text-align: center;
        padding-left: 5px;
        padding-right: 5px;
    `}
    ${(props) =>
      props.type === 'map' &&
      `
      background-color: #f5f5f5;
    `}
  }
  p {
    &.mapCaption {      
      color: ${(props) => props.theme.colorWhite};
      margin-bottom: 0;
      @media (max-width: 575px) {
        color: #7a7a7a;
      }
      span {
        display: block;
        &:first-of-type {
          position: relative;
          padding-left: 15px;
          margin-bottom: 5px;
          &:before {
            content: "";
            position: absolute;
            top: 50%;
            left: 0;
            display: block;
            width: 8px;
            height: 8px;
            background-color: #d7323d;
            border-radius: 50%;
            margin-top: -4px;
          }
        }
      }
    }
  }
`

const WaterWhyBGWrapper = styled(BackgroundImage)`
  height: 100%;
  background-position-y: top;
  @media (max-width: 575px) {
    padding-left: 20px;
    padding-right: 20px;
  }
`

const WaterMapBGWrapper = styled(BackgroundImage)`
  height: 100%;
  background-position: center !important;
  background-size: cover !important;
  @media (min-width: 576px) and (max-width: 767px) {
    padding-top: 60%;
  }
  @media (max-width: 575px) {
    padding-top: 100%;
  }
  &:before,
  &:after {
    right: 0 !important;
  }
`

const WaterTitleWhy = styled(WaterTitle)`
  text-align: left;
  padding-top: 60px;
  margin-bottom: 30px;
  @media (min-width: 768px) and (max-width: 991px) {
    padding-top: 50px;
    margin-bottom: 20px;
  }
  @media (max-width: 575px) {
    text-align: center;
  }
`

const WaterMeteoWrapper = styled(Col)`
  text-align: right;
  @media (max-width: 575px) {
    text-align: center;
  }
`

const WaterMeteoLink = styled(WaterLink)`
  margin-bottom: 0 !important;
  @media (min-width: 1921px) {
    margin-right: 15%;
  }
  @media (max-width: 575px) {
    font-size: 11px;
  }
  @media (max-width: 340px) {
    font-size: 10px;
  }
  &:before {
    content: none;
  }
`

const WaterProgram = styled(Row)`
  padding-top: 60px;
  @media (max-width: 575px) {
    padding-left: 20px;
    padding-right: 20px;
  }
`

const WaterProgramCard = styled(Col)`
  display: flex;
  ${(props) =>
    props.align === 'left' &&
    `
    align-items: flex-end;
    justify-content: flex-end;
  `}
  ${(props) =>
    props.align === 'right' &&
    `
    align-items: flex-end;
    justify-content: flex-start;
    // padding-right: 15%;
  `}
  @media (min-width: 991px) {
    ${(props) =>
      props.align === 'right' &&
      `
      padding-right: 15%;
    `}
  }
  p {
    text-align: left;
    margin-bottom: 20px;
  }
`

const WaterProgramTitle = styled(Title)`
  width: 100%;
  text-align: right;
  line-height: 1;
  margin-bottom: 0;
  @media (max-width: 767px) {
    text-align: left;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    margin-bottom: 30px;
  }
  @media (max-width: 575px) {
    margin-bottom: 25px;
  }
  span {
    display: block;
    &:first-of-type {
      display: inline-block;
      color: ${(props) => props.theme.textColor};
      font-family: ${(props) => props.theme.fontPlayfairDisplay};
      font-size: 22px;
      font-weight: 400;
      @media (min-width: 768px) and (max-width: 991px) {
        font-size: 19px;
      }
      @media (min-width: 576px) and (max-width: 767px) {
        font-size: 18px;
      }
      @media (max-width: 575px) {
        font-size: 16px;
      }
    }
    &:last-of-type {
      color: ${(props) => props.theme.colorGreen};
      font-size: 90px;
      @media (min-width: 768px) and (max-width: 991px) {
        font-size: 70px;
      }
      @media (min-width: 576px) and (max-width: 767px) {
        font-size: 62px;
      }
      @media (max-width: 575px) {
        font-size: 36px;
      }
      br {
        @media (max-width: 767px) {
          display: none;
        }
      }
    }
  }
`

const WaterGarden = styled(Row)`
  display: flex;
  justify-content: center;
  position: relative;
  @media (max-width: 575px) {
    padding-left: 25px;
    padding-right: 25px;
  }
  &:after {
    content: '';
    position: absolute;
    left: 20px;
    right: 20px;
    bottom: 0;
    display: block;
    height: 500px;
    background-image: url(${waterGardenBottomBGImage});
    background-repeat: no-repeat;
    background-position: bottom center;
    background-size: contain;
    @media (max-width: 575px) {
      left: 15px;
      right: 15px;
    }
  }
`

const WaterGardenCol = styled(Col)`
  background-image: url(${waterGardenBGImage});
  background-repeat: no-repeat;
  background-position: top center;
  background-size: cover;
  padding-top: 50px;
  padding-left: 9.5%;
  padding-right: 9.5%;
  padding-bottom: 23%;
  margin-top: 40px;
  @media (min-width: 576px) and (max-width: 767px) {
    padding-bottom: 30%;
  }
  @media (max-width: 575px) {
    background-size: 130%;
    text-align: center;
  }
  p {
    line-height: 1.4;
    margin-bottom: 30px;
    span {
      position: relative;
      color: ${(props) => props.theme.colorWhite};
      line-height: 1.3;
      margin-bottom: 6px;
      z-index: 10;
    }
  }
`

const WaterGardenTitle = styled(Title)`
  width: 100%;
  text-align: center;
  margin-bottom: 50px;
  span {
    display: block;
    &:first-of-type {
      color: ${(props) => props.theme.textColor};
      font-size: 30px;
    }
    &:last-of-type {
      color: ${(props) => props.theme.colorGreen};
      font-size: 44px;
    }
  }
`

const Water = () => {
  const data = useStaticQuery(graphql`
    query {
      waterTopBGImage: file(relativePath: { eq: "water_top_bg.png" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      waterBottomBGImage: file(relativePath: { eq: "manners_bg.png" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      waterMapBGImage: file(relativePath: { eq: "water_map.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 905) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      waterWhyBGImage: file(relativePath: { eq: "water_why_bg.png" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      waterSlideImage1: file(relativePath: { eq: "water_slide_1.png" }) {
        childImageSharp {
          fluid(maxWidth: 200) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      waterSlideImage2: file(relativePath: { eq: "water_slide_2.png" }) {
        childImageSharp {
          fluid(maxWidth: 200) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  `)

  const slides1Items = [
    {
      title: `Ocieplenie klimatu`,
      shortText: `O tym, że żar leje się z nieba, możesz się Waść przekonać na własnej skórze. Temperatura rośnie, woda rychlej paruje, a deszczu próżno wyglądać. Konsekwencje tego takie, że bilans wody robi się ujemny – wysusza się gleba, spada poziom wód gruntowych, wysychają rzeki, mokradła, rozlewiska, jeziora. A z pustego to wiadomo… nawet najbardziej pojętny kum nie naleje.`,
      text: `<p>O tym, że żar leje się z nieba, możesz się Waść przekonać na własnej skórze. Temperatura rośnie, woda rychlej paruje, a deszczu próżno wyglądać. Konsekwencje tego takie, że bilans wody robi się ujemny – wysusza się gleba, spada poziom wód gruntowych, wysychają rzeki, mokradła, rozlewiska, jeziora. A z pustego to wiadomo… nawet najbardziej pojętny kum nie naleje.</p>`,
    },
    {
      title: `Bezśnieżne zimy`,
      shortText: `„Onegdaj to były zimy, a nie to, co teraz”. Już to Waść gdzieś słyszał? No to teraz może zobaczyć. Bo śnieg widać, owszem, ale raczej na zdjęciach i filmach. Z roku na rok zimy w Polsce są coraz cieplejsze, a opady śniegu skromne. Do tego topnieją za szybko, a razem z nim zasoby wody, które na wiosnę zasilały glebę w wilgoć. Teraz zasilać nie ma czym, wszak woda z opadów zimowych w kwietniu pływa już w Bałtyku.`,
      text: `<p>„Onegdaj to były zimy, a nie to, co teraz”. Już to Waść gdzieś słyszał? No to teraz może zobaczyć. Bo śnieg widać, owszem, ale raczej na zdjęciach i filmach. Z roku na rok zimy w Polsce są coraz cieplejsze, a opady śniegu skromne. Do tego topnieją za szybko, a razem z nim zasoby wody, które na wiosnę zasilały glebę w wilgoć. Teraz zasilać nie ma czym, wszak woda z opadów zimowych w kwietniu pływa już w Bałtyku.</p>`,
    },
    {
      title: `Gwałtowne ulewy`,
      shortText: `Z wielkiej chmury… wielki deszcz. Najpierw każe na siebie długo czekać, trzeba go ładnie prosić, błagać niemal, a jak już spadnie to tak gwałtownie, że wysuszona ziemia nie jest go w stanie wchłonąć i na dłużej zatrzymać. Cały nasz kraj pocięty jest siecią rowów melioracyjnych, które w praktyce jedynie odprowadzają wodę. I choć deszcz pada, spływa wprost do kanalizacji, strumieni i rzek, czasami po drodze podtopi kilka piwnic, a potem plusk – prosto do morza. I tyle go widzieliśmy. A susza jak była, tak jest.`,
      text: `<p>Z wielkiej chmury… wielki deszcz. Najpierw każe na siebie długo czekać, trzeba go ładnie prosić, błagać niemal, a jak już spadnie to tak gwałtownie, że wysuszona ziemia nie jest go w stanie wchłonąć i na dłużej zatrzymać. Cały nasz kraj pocięty jest siecią rowów melioracyjnych, które w praktyce jedynie odprowadzają wodę. I choć deszcz pada, spływa wprost do kanalizacji, strumieni i rzek, czasami po drodze podtopi kilka piwnic, a potem plusk – prosto do morza. I tyle go widzieliśmy. A susza jak była, tak jest.</p>`,
    },
  ]

  const slides2Items = [
    {
      title: null,
      text: `<p>Woda stanowi ok. 92% gotowego piwa, co wpływa na jego jakość.</p>`,
    },
    {
      title: null,
      text: `<p>CELE 2030: o 50% zmniejszyć zużycie wody w naszym browarze [po rozwinięciu] Woda zawsze będzie niezbędna, ale zmniejszając jej zużycie o połowę, upewnimy się, że nic się nie marnuje.</p>`,
    },
  ]
  const [slides1ModalOpen, setSlides1ModalOpen] = useState(false)
  const [slides2ModalOpen, setSlides2ModalOpen] = useState(false)

  const handleSlides1ModalClose = () => {
    setSlides1ModalOpen(false)
  }

  const handleSlides2ModalClose = () => {
    setSlides2ModalOpen(false)
  }

  return (
    <WaterWrapper id="water" className="withBubbles">
      <ModalSlides
        allItems={slides1Items}
        isOpen={slides1ModalOpen}
        onClose={handleSlides1ModalClose}
      />
      <ModalSlides
        type="water2"
        images={data}
        allItems={slides2Items}
        isOpen={slides2ModalOpen}
        onClose={handleSlides2ModalClose}
      />
      <WaterTopBGWrapper
        Tag="div"
        className="waterTopBGImage"
        fluid={data.waterTopBGImage.childImageSharp.fluid}
        backgroundColor={`#f9f8f6`}
      >
        <Container>
          <Row>
            <WithBubblesWrapper>
              {Array(30)
                .fill()
                .map((_, index) => {
                  return <div className={`bubble x${index + 1}`} key={index} />
                })}
            </WithBubblesWrapper>
            <WaterColWrapper>
              <WaterTitle>
                <span>Przykład płynie</span> <span>z góry...</span>
              </WaterTitle>
              <WaterTextWrapper>
                <p>
                  Większość surowców i{'\u00A0'}składników naszych produktów{' '}
                  pochodzi prosto z{'\u00A0'}natury, z{'\u00A0'}Sierpca. W
                  {'\u00A0'}tym woda. A{'\u00A0'}bez dobrej wody nie byłoby
                  smacznego piwa!
                </p>
                <p>
                  To, co{'\u00A0'}czynimy, wpływa na{'\u00A0'}region. A my
                  {'\u00A0'}od{'\u00A0'}lat dbamy o{'\u00A0'}to, żeby wpływało
                  pozytywnie. W końcu woda to{'\u00A0'}nasze wspólne dobro.{' '}
                  Jednak musimy pamiętać, że{'\u00A0'}jej zasoby są{'\u00A0'}
                  ograniczone i{'\u00A0'}z{'\u00A0'}roku na rok, jest jej coraz
                  mniej.
                </p>
                <WaterLink
                  className="btn"
                  onClick={() => setSlides2ModalOpen(true)}
                >
                  więcej o wodzie
                </WaterLink>
              </WaterTextWrapper>
            </WaterColWrapper>
          </Row>
        </Container>
      </WaterTopBGWrapper>
      <WaterBottomBGWrapper
        Tag="div"
        className="waterBottomBGImage"
        fluid={data.waterBottomBGImage.childImageSharp.fluid}
        backgroundColor={`transparent`}
      >
        <WaterContainerBottom fluid>
          <Row>
            <WaterCard md={6}>
              <WaterWhyBGWrapper
                Tag="div"
                className="waterWhyBGImage"
                fluid={data.waterWhyBGImage.childImageSharp.fluid}
                backgroundColor={`#be2b34`}
              >
                <WaterCardWrapper align="left" type="why">
                  <WaterTitleWhy as="h3">
                    W czasie suszy,
                    <br /> szosa sucha.
                  </WaterTitleWhy>
                  <p>
                    Susza powoduje obniżenie jakości i{'\u00A0'}dostępności
                    wody. Być może trudno w{'\u00A0'}to{'\u00A0'}uwierzyć, ale{' '}
                    Polska jest obecnie jednym z{'\u00A0'}krajów o{'\u00A0'}
                    <RoughNotation
                      show={true}
                      color="#8c8a8c"
                      animate={false}
                      padding={2}
                      type="highlight"
                      multiline={true}
                    >
                      najmniejszych zasobach wody w Unii Europejskiej.
                    </RoughNotation>{' '}
                    Bliżej nam w tej kwestii do… Egiptu.
                  </p>
                  <WaterLinkWrapper>
                    <WaterLink
                      type="why"
                      className="btn"
                      onClick={() => setSlides1ModalOpen(true)}
                    >
                      Dlaczego tak się dzieje?
                    </WaterLink>
                  </WaterLinkWrapper>
                </WaterCardWrapper>
              </WaterWhyBGWrapper>
            </WaterCard>
            <WaterCard md={6}>
              <WaterMapBGWrapper
                Tag="div"
                className="waterMapBGImage"
                fluid={data.waterMapBGImage.childImageSharp.fluid}
                backgroundColor={`#f1ebdf`}
              >
                <WaterCardWrapper align="right" type="map">
                  <p className="mapCaption">
                    <span>0-20%</span>
                    <span>
                      wskaźnik wilgotności gleby w Polsce
                      <br /> do 7 cm głębokości.{' '}
                    </span>
                  </p>
                </WaterCardWrapper>
              </WaterMapBGWrapper>
            </WaterCard>
          </Row>
          <Row>
            <WaterMeteoWrapper xs={12}>
              <WaterMeteoLink
                as="a"
                href="https://meteo.imgw.pl/"
                className="btn"
                target="_blank"
                rel="noopener noreferrer"
              >
                Sprawdź warunki pogodowe dla Twojej miejscowości
              </WaterMeteoLink>
            </WaterMeteoWrapper>
          </Row>
          <WaterProgram>
            <WaterProgramCard md={6} align="left">
              <WaterProgramTitle as="h2">
                <span>Dlatego stworzyliśmy program</span>
                <span>
                  Pieczą
                  <br /> otaczamy...
                </span>
              </WaterProgramTitle>
            </WaterProgramCard>
            <WaterProgramCard md={6} align="right">
              <p>
                …który chroni zasoby wody i pozwala gospodarzyć nią oszczędnie.
                Wiemy, że przeciwdziałanie skutkom suszy to zadanie niełatwe i
                wymaga gry zespołowej. Dołącz Waść do Drużyny Kasztelana i razem
                z nami region pieczą otaczaj! Na przykład dzięki małej retencji.{' '}
              </p>
            </WaterProgramCard>
          </WaterProgram>
          <div>
            <WaterGarden>
              <WaterGardenCol sm={10} md={8} lg={6}>
                <WaterGardenTitle>
                  <span>mała</span>
                  <span>WIELKA retencja</span>
                </WaterGardenTitle>
                <p>
                  Mała retencja to{'\u00A0'}proste sposoby, które pomagają
                  zatrzymać i{'\u00A0'}
                  gromadzić wodę w{'\u00A0'}okolicy oraz spowolnić jej rychłą
                  ucieczkę do{'\u00A0'}
                  rzek i{'\u00A0'}morza. Taką funkcję pełnią między innymi oczka
                  i{'\u00A0'}
                  zbiorniki wodne, łąki kwietne i{'\u00A0'}ogrody deszczowe, ale
                  również zatrzymywanie deszczówki i{'\u00A0'}wykorzystywanie
                  jej podczas suszy np.{'\u00A0'}do{'\u00A0'}podlewania ogrodów.
                </p>
              </WaterGardenCol>
            </WaterGarden>
          </div>
        </WaterContainerBottom>
      </WaterBottomBGWrapper>
    </WaterWrapper>
  )
}

export default Water
