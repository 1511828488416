import React, { useState, useCallback, useEffect } from 'react'
import axios from 'axios'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import styled from 'styled-components'
import { Col, Row, Container } from 'reactstrap'
import { Formik } from 'formik'
import { useDropzone } from 'react-dropzone'
import { Link as LinkScroll } from 'react-scroll'

import {
  Title,
  Button,
  ButtonCollapse,
  SubmitForm,
  SubmitFormRow,
  SubmitFormGroup,
  SubmitFormField,
  SubmitFormDragWrapper,
  SubmitFormCheckBoxField,
  SubmitFormCheckBoxLabel,
  SubmitFormCollapseWrapper,
  SubmitFormCollapse,
  SubmitFormSubmitWrapper,
} from './elements'
import ModalSlides from './modal-slides'
import ModalSingle from './modal-single'

const ContestWrapper = styled.div`
  padding-top: 200px;
  @media (min-width: 768px) and (max-width: 991px) {
    padding-top: 120px;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    padding-top: 100px;
  }
  @media (max-width: 575px) {
    padding-top: 20px;
  }
`

const ContestTitle = styled(Title)`
  color: #788056;
  line-height: 0.9;
  margin-bottom: 50px;
`

const ContestIDescWrapper = styled.div`
  position: relative;
  color: ${(props) => props.theme.colorWhite};
  p {
    color: #a19187;
    margin-bottom: 30px;
    @media (min-width: 576px) and (max-width: 767px) {
      margin-bottom: 25px;
    }
  }
`

const ContestFormColWrapper = styled(Col)`
  @media (max-width: 575px) {
    padding-left: 20px;
    padding-right: 20px;
    margin-top: -30px;
  }
`

const ContestForm = styled(SubmitForm)``

const ContestFormRow = styled(SubmitFormRow)``

const ContestFormGroup = styled(SubmitFormGroup)``

const ContestFormField = styled(SubmitFormField)``

const ContestFormDragWrapper = styled(SubmitFormDragWrapper)`
  @media (max-width: 575px) {
    /* display: none; */
  }
`

const ContestFormButtonUpload = styled(Button)`
  display: inline-block;
  cursor: pointer;
  @media (max-width: 575px) {
    padding-right: 20px;
  }
  &:after {
    @media (max-width: 575px) {
      content: none;
    }
  }
`

const ContestFormCheckBoxField = styled(SubmitFormCheckBoxField)``

const ContestFormCheckBoxLabel = styled(SubmitFormCheckBoxLabel)``

const ContestFormCollapseWrapper = styled(SubmitFormCollapseWrapper)``

const ContestFormCollapse = styled(SubmitFormCollapse)``

const ContestFormSubmitWrapper = styled(SubmitFormSubmitWrapper)``

const ContestFormImageColWrapper = styled(Col)`
  @media (max-width: 575px) {
    order: -1;
  }
`

const ContestKnowledgeTitle = styled(ContestTitle)`
  color: #85764e;
  font-size: 90px;
  text-align: right;
  @media (min-width: 992px) and (max-width: 1199px) {
    font-size: 84px;
  }
  @media (min-width: 768px) and (max-width: 991px) {
    font-size: 80px;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    font-size: 74px;
    text-align: center;
  }
  @media (max-width: 575px) {
    font-size: 54px;
    text-align: center;
    margin-bottom: 25px;
  }
`

const ContestKnowledgeRowWrapper = styled(Row)`
  margin-top: 100px;
  margin-bottom: 150px;
  @media (min-width: 576px) and (max-width: 767px) {
    margin-top: 80px;
    margin-bottom: 120px;
  }
  @media (max-width: 575px) {
    margin-top: 50px;
    margin-bottom: 90px;
  }
  p {
    margin-bottom: 1.5rem;
  }
`

const ContestKnowledgeColWrapper = styled(Col)`
  @media (max-width: 575px) {
    text-align: center;
    padding-left: 20px;
    padding-right: 20px;
  }
`

const Contest = (props) => {
  const data = useStaticQuery(graphql`
    query {
      contestImage: file(relativePath: { eq: "contest.png" }) {
        childImageSharp {
          fluid(maxWidth: 640) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  `)

  const [files, setFiles] = useState([])
  useEffect(() => {
    files.forEach((file, fileIdx) => {
      if (!file.toUpload) return

      axios
        .post(process.env.SUBMISSION_PHOTOS_INITIATE_UPLOAD_URL, {
          contentType: file.file.type,
          name: file.file.name,
        })
        .then((resp) => {
          axios
            .put(resp.data.s3PutObjectUrl, file.file, {
              headers: {
                'Content-Type': file.file.type,
                'X-AMZ-Meta-Name': file.file.name,
                'X-AMZ-Meta-PhotoId': resp.data.photoId,
              },
            })
            .then((respUpload) => {
              if (respUpload.status === 200) {
                setFiles((fs) =>
                  fs.map((f, fIdx) => {
                    if (fIdx === fileIdx) {
                      f = {
                        ...f,
                        isUploading: false,
                        toUpload: false,
                        key: resp.data.photoKey,
                      }
                    }
                    return f
                  })
                )
              }
            })
        })
    })
  }, [files])
  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.forEach((file, fileIdx) => {
      setFiles((fs) => [
        ...fs,
        {
          name: file.name,
          isUploading: true,
          toUpload: true,
          file: file,
        },
      ])
    })
  }, [])

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: 'image/*',
  })

  const [isOpen, setIsOpen] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isError, setIsError] = useState(false)
  const [isSuccess, setIsSuccess] = useState(false)

  const toggle = () => setIsOpen(!isOpen)
  const handleModalClose = () => setIsModalOpen(false)
  const handleModalOpen = () => setIsModalOpen(true)

  const setError = () => {
    setIsError(true)
  }

  const cancelError = () => {
    setIsError(false)
  }

  const setSuccess = () => {
    setIsSuccess(true)
  }

  const cancelSuccess = () => {
    setIsSuccess(false)
  }

  const resetFormModals = () => {
    setIsSuccess(false)
    setIsError(false)
  }

  const modalSlidesItems = [
    {
      title: `Ciekawostka 1`,
      shortText: ``,
      text: `<p>W Browarze Kasztelan mamy baczenie na każdą kroplę wody. Dlatego inwestujemy, modernizujemy, optymalizujemy, uszczelniamy, odzyskujemy i staramy się wykorzystać tę samą wodę na różnych etapach produkcji piwa. Przykład? Woda z chłodzenia przekazywana jest na linię pakowania i służy do podgrzania piwa przed napełnieniem puszek. Z kolei wodę do płukania puszek ponownie wykorzystuje się do ich zraszania po nalewie, żeby np. oczyścić je z piany. Staramy się tworzyć obieg zamknięty, żeby ni kropli nie wylewać po próżnicy!</p>`,
    },
    {
      title: `Ciekawostka 2`,
      shortText: ``,
      text: `<p>Z pewnością ciekawa będzie dla Waści wieść, że według Światowego Funduszu na rzecz Przyrody <strong>tylko 2,5% światowych zasobów wody to woda słodka</strong>, z czego większość jest zamrożona w lodowcach lub z innych przyczyn niedostępna.</p>`,
    },
    {
      title: `Ciekawostka 3`,
      shortText: ``,
      text: ``,
      comp: (
        <p>
          Polacy zużywają średnio{' '}
          <strong>150 litrów wody na osobę dziennie</strong>, z czego około{' '}
          <strong>148 litrów trafia do kanalizacji</strong>, a zaledwie{' '}
          <strong>1-2 litry są spożywane</strong>. Warto więc wodę oszczędzać i
          sumienie czyste zachować. Jak? Racz zerknąć Waść do{' '}
          <LinkScroll
            to="manners"
            href="#manners"
            smooth
            onClick={() => {
              handleModalClose()
              return true
            }}
          >
            Kasztelańskiego Pocztu Obyczajów
          </LinkScroll>
        </p>
      ),
    },
    {
      title: `Ciekawostka 4`,
      shortText: ``,
      text: `<p><strong>Kasztelan monitoruje na bieżąco zużycie wody w browarze</strong>. Dzięki temu jesteśmy w stanie natychmiast zareagować w przypadku niekontrolowanych wycieków wody i rychło w czas je wyeliminować.</p>`,
    },
    {
      title: `Ciekawostka 5`,
      shortText: ``,
      text: `<p>Jak wygląda woda? W lustro racz spojrzeć Waść. Wszak <strong>Twa dostojna postać w 60-70% składa się z wody</strong>, która znajduje się w każdej Twojej komórce. To ona stanowi około <strong>75-80% mózgu</strong>, ponad <strong>80% krwi i 75% mięśni</strong>. Nawet kości złożone są z wody w <strong>22%</strong>.</p>`,
    },
    {
      title: `Ciekawostka 6`,
      shortText: ``,
      text: `<p>Zwiędła, zapomniana marchewka na dnie lodówki. Jogurt, z którym niebawem będziesz Waść świętował 3 miesiące znajomości. To wszystko produkty, które, jesteśmy gotowi się założyć, miast na talerzu wylądują na dnie kosza. A niemarnowanie żywności to ważki krok ku ograniczeniu zużycia wody. <strong>Do produkcji 1 kg chleba potrzeba wszak ponad 1600 litrów wody, do 1 kg jabłek ponad 820 litrów, a do 1 kg mięsa wołowego aż 15 415 litrów wody!</strong> Tak więc zanim Waść wybierzesz się na zakupy, zajrzyj do lodówki i szafek i sprawdź, czy na pewno jest taka potrzeba.</p>`,
    },
    {
      title: `Ciekawostka 7`,
      shortText: ``,
      text: `<p>Czy wiedziałeś, że <strong>każda rzecz, którą jemy, w którą się ubieramy i którą używamy, nie powstałaby bez wody? To ślad wodny</strong>, który każdy z nas odciska na planecie. Jednak możemy zrobić coś, żeby był jak najmniejszy. A do tego potrzebny jest rozsądek i umiar w konsumowaniu dobra wszelakiego, m.in. tego, gdzie Waść robisz zakupy i później chowasz do szafy i lodówki.</p>`,
    },
    {
      title: `Ciekawostka 8`,
      shortText: ``,
      text: `<p>Najlepsze pomysły przychodzą podobno podczas kąpieli. Możesz sam sprawdzić, ale najpierw pozwól, że podzielimy się takim faktem. <strong>Około 40% wody w gospodarstwach domowych zużywamy na cele higieniczne. Wanna</strong> napełniona do połowy to <strong>aż 135 litrów wody! Szybki prysznic</strong> znacznie mniej, bo około <strong>40-50 litrów</strong>. Dlatego wannę na rzecz prysznica porzuć Waść, wody oszczędź!</p>`,
    },
    {
      title: `Ciekawostka 9`,
      shortText: ``,
      text: `<p><strong>Czy woda pitna to w Polsce towar deficytowy?</strong> Zanim pokręcisz Waść głową na znak sprzeciwu, doczytaj cierpliwie do końca. Otóż problem ten, który kojarzy się nam głównie z krajami Afryki i Azji, dotyczy <strong>też 100 mln Europejczyków</strong>. Według Europejskiej Agencji Środowiskowej <strong>na jednego Europejczyka przypada rocznie ok. 4500 m3 wody, przy czym na mieszkańca Polski 1600 m3 i poniżej 1000 m3 w okresie suszy</strong>.</p>`,
    },
    {
      title: `Ciekawostka 10`,
      shortText: ``,
      text: `<p>Toastów za zdrowie z pewnością Waść nie odmawiasz i sam je ochoczo wznosisz. Być może zainteresuje Cię więc fakt, że brak wody i jej niska jakość wpływa negatywnie na ludzki dobrostan. <strong>Według WHO problem ten powoduje 80% chorób w krajach rozwijających się</strong>.</p>`,
    },
    {
      title: `Ciekawostka 11`,
      shortText: ``,
      text: `<p>Powodów bezsenności może być wiele. Jedni nie śpią, bo trzymają kredens, innym <strong>sen z powiek spędza kapiący kran</strong>. I słusznie. W końcu to nie irytujący, jednostajny dźwięku jest w tym wszystkim najgorszy. Przede wszystkim uwagę zwróć, że <strong>jeżeli kran przecieka i woda kapie z częstotliwością 1 kropli na sekundę – rocznie marnujemy prawie 12 tys. litrów wody</strong>!</p>`,
    },
  ]

  return (
    <ContestWrapper id="konkurs">
      <ModalSlides
        allItems={modalSlidesItems}
        isOpen={isModalOpen}
        onClose={handleModalClose}
      />

      <Container>
        {process.env.NODE_ENV !== 'production' && (
          <Row>
            <ContestFormColWrapper xs={12} lg={6}>
              <ContestTitle>
                Konkurs!
                <br /> Pieczą otaczamy –<br /> przyrodę uwieczniamy
              </ContestTitle>
              <ContestIDescWrapper>
                <p>
                  Kasztelan ma baczenie na otoczenie, dlatego szanuje przyrodę i
                  lubi na jej urodzie oko zawiesić.
                </p>
                <p>
                  A przyroda nie przetrwałaby bez wody! Dołącz Waść do Drużyny
                  Kasztelana i uwiecznij najpiękniejsze zielone miejsca w
                  Sierpcu i jego okolicach. Prześlij nam zdjęcie przedstawiające
                  naturę w Twoim najbliższym otoczeniu. To może być las, łąka,
                  oczko wodne, a także Twój ogród czy zielono urządzony balkon.
                  Jednak co by nie było za łatwo – na zdjęciu musisz uchwycić
                  także wodę! Aparaty w dłoń i do dzieła!
                </p>
              </ContestIDescWrapper>
              <div>
                <Formik
                  initialValues={{
                    name: '',
                    surname: '',
                    email: '',
                    phone: '',
                    files: [],
                    tosaccept: false,
                    years18: false,
                    place: false,
                    processing: false,
                    telecommunication: false,
                    marketing: false,
                  }}
                  validate={(values) => {
                    const errors = {}

                    for (let key in values) {
                      if (key === 'marketing') {
                        continue
                      }
                      if (!values[key]) {
                        errors[key] = 'To pole jest wymagane.'
                      }
                    }

                    if (
                      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(
                        values.email
                      )
                    ) {
                      errors.email = 'Nieprawidłowy adres e-mail.'
                    }

                    if (files.length === 0) {
                      errors.files = 'Wgraj przynajmniej jedno zdjęcie.'
                    }

                    return errors
                  }}
                  onSubmit={(values, { setSubmitting, resetForm }) => {
                    axios
                      .post(process.env.SUBMISSION_PHOTOS_URL, {
                        firstName: values.name,
                        lastName: values.surname,
                        email: values.email,
                        phone: values.phone,
                        tosaccept: values.tosaccept,
                        tos1: values.years18,
                        tos2: values.place,
                        tos3: values.processing,
                        tos4: values.telecommunication,
                        tos5: values.marketing,
                        files: files.map((f) => ({
                          name: f.name,
                          key: f.key,
                        })),
                      })
                      .then((resp) => {
                        cancelError()
                        setSuccess()

                        setSubmitting(false)
                        resetForm()
                      })
                      .catch(() => {
                        setError()
                        cancelSuccess()

                        setSubmitting(false)
                      })
                  }}
                >
                  {({ isSubmitting, errors, values }) => {
                    return (
                      <>
                        {!isError && isSuccess && (
                          <ModalSingle
                            item={{
                              title: `Zgłoszenie wysłane`,
                              text: `<p>Gratulujemy Waszmości lekkości pióra i trzymamy kciuki! <br> Powodzenia!</p>`,
                            }}
                            isShow={true}
                            handleToggle={resetFormModals}
                          />
                        )}
                        {isError && !isSuccess && (
                          <ModalSingle
                            item={{
                              title: `Wystąpił błąd`,
                              text: `<p>Prosimy odświeżyć stronę i spróbować ponownie.</p>`,
                            }}
                            isShow={true}
                            handleToggle={resetFormModals}
                          />
                        )}
                        <ContestForm>
                          <ContestFormRow className="form-row">
                            <ContestFormGroup
                              className="form-group"
                              md={6}
                              align="left"
                            >
                              <ContestFormField
                                type="text"
                                name="name"
                                id="name"
                                className="form-control"
                                placeholder="Wpisz swoje imię"
                                error={errors.name}
                              />
                            </ContestFormGroup>
                            <ContestFormGroup
                              className="form-group"
                              md={6}
                              align="right"
                            >
                              <ContestFormField
                                type="text"
                                name="surname"
                                id="surname"
                                className="form-control"
                                placeholder="Wpisz swoje nazwisko"
                                error={errors.surname}
                              />
                            </ContestFormGroup>
                            <ContestFormGroup className="form-group" xs={12}>
                              <ContestFormField
                                type="text"
                                name="email"
                                id="email"
                                className="form-control"
                                placeholder="Wpisz swój adres email"
                                error={errors.email}
                              />
                            </ContestFormGroup>
                            <ContestFormGroup className="form-group" xs={12}>
                              <ContestFormField
                                type="text"
                                name="phone"
                                id="phone"
                                className="form-control"
                                placeholder="Wpisz swój numer telefonu"
                                error={errors.phone}
                              />
                            </ContestFormGroup>
                            <ContestFormGroup className="form-group" xs={12}>
                              <ContestFormDragWrapper {...getRootProps()}>
                                <input {...getInputProps()} />
                                {isDragActive ? (
                                  <p>Upuść pliki tutaj ...</p>
                                ) : (
                                  <p>
                                    <span>Wybierz</span> lub upuść TU plik
                                  </p>
                                )}
                              </ContestFormDragWrapper>

                              {false && (
                                <ContestFormButtonUpload
                                  htmlFor="files"
                                  as="label"
                                >
                                  DODAJ PLIK +
                                </ContestFormButtonUpload>
                              )}

                              {false && (
                                <ContestFormField
                                  type="file"
                                  name="files"
                                  id="files"
                                  accept="image/*"
                                  className="form-control"
                                  multiple={true}
                                  error={errors.files}
                                  {...getInputProps()}
                                />
                              )}

                              {files && (
                                <ul>
                                  {files.map((file, fileIdx) => (
                                    <li key={`files-${fileIdx}`}>
                                      {file.name} -{' '}
                                      {file.isUploading ? 'uploading' : 'ok'}
                                    </li>
                                  ))}
                                </ul>
                              )}
                            </ContestFormGroup>
                            <ContestFormGroup className="form-group" xs={12}>
                              <div className="form-check">
                                <ContestFormCheckBoxField
                                  type="checkbox"
                                  name="tosaccept"
                                  id="tosaccept"
                                  className="custom-control-input"
                                />
                                <ContestFormCheckBoxLabel
                                  className="custom-control-label"
                                  htmlFor="tosaccept"
                                  error={errors.tosaccept}
                                >
                                  * Oświadczam, że zapoznałem się z regulaminem
                                  konkursu i akceptuję jego postanowienia.
                                </ContestFormCheckBoxLabel>
                              </div>
                            </ContestFormGroup>
                            <ContestFormGroup className="form-group" xs={12}>
                              <div className="form-check">
                                <ContestFormCheckBoxField
                                  type="checkbox"
                                  name="years18"
                                  id="years18"
                                  className="custom-control-input"
                                />
                                <ContestFormCheckBoxLabel
                                  className="custom-control-label"
                                  htmlFor="years18"
                                  error={errors.years18}
                                >
                                  * Mam ukończone 18 lat
                                </ContestFormCheckBoxLabel>
                              </div>
                            </ContestFormGroup>
                            <ContestFormGroup className="form-group" xs={12}>
                              <div className="form-check">
                                <ContestFormCheckBoxField
                                  type="checkbox"
                                  name="place"
                                  id="place"
                                  className="custom-control-input"
                                />
                                <ContestFormCheckBoxLabel
                                  className="custom-control-label"
                                  htmlFor="place"
                                  error={errors.place}
                                >
                                  * Mieszkam w Sierpcu
                                </ContestFormCheckBoxLabel>
                              </div>
                              <ContestFormCollapseWrapper>
                                <ContestFormCollapse isOpen={isOpen}>
                                  <p>
                                    1. Administratorem danych osobowych
                                    udostępnianych w powyższym formularzu jest
                                    Carlsberg Polska Sp. z o.o. z siedzibą w
                                    Warszawie (02-255), ul. Krakowiaków 34.
                                  </p>
                                  <p>
                                    2. Jestem świadoma/y,że podanie powyższych
                                    danych jest dobrowolne, a
                                    także,żeprzysługuje mi prawo dostępu do
                                    treści moich danych i otrzymania ich kopii
                                    oraz, z zastrzeżeniemobowiązujących
                                    przepisów prawa, prawo do ich sprostowania,
                                    usunięcia,ograniczenia przetwarzania, prawo
                                    do przenoszenia danych, prawo do wniesienia
                                    sprzeciwu i prawo do cofnięcia zgody w
                                    dowolnym momencie.
                                  </p>
                                  <p>
                                    3. Przetwarzanie danych osobowych podanych w
                                    formularzu kontaktowym odbywaćsiębędzie na
                                    zasadach przewidzianych w Rozporządzeniu
                                    Parlamentu Europejskiego i Rady (UE)
                                    2016/679 z dnia 27 kwietnia 2016 r. w
                                    sprawie ochrony osób fizycznych w związku z
                                    przetwarzaniem danych osobowych i w sprawie
                                    swobodnego przepływu takich danych oraz
                                    uchylenia dyrektywy 95/46/WE (ogólne
                                    rozporządzenie o ochronie danych).
                                  </p>
                                  <p>
                                    4. Celem przetwarzania podanych danych
                                    osobowych jest wyłonieniezwycięzcy konkursu
                                    i możliwość kontaktu ze zwycięzcami.
                                  </p>
                                  <p>
                                    5. Podstawą do przetwarzania danych
                                    osobowych jest zgoda na przetwarzanie danych
                                    osobowych.
                                  </p>
                                  <p>
                                    6. Podane w formularzu dane osobowe będą
                                    przechowywane będą przez okres niezbędny do
                                    zakończenia konkursu lub do momentu
                                    odwołania zgody, a po tym terminie tylko w
                                    przypadku gdy tak będziewynikało z przepisów
                                    prawa.
                                  </p>
                                  <p>
                                    7. Dostęp do przekazanych danych osobowych
                                    mogąuzyskaćnastępujące kategorie odbiorców:
                                    upoważnieni pracownicy i przedstawiciele
                                    Administratora oraz podmioty z Grupy
                                    Carlsberg, a także podmioty
                                    zewnętrznedziałające na zlecenie
                                    Administratora. Dane osobowe
                                    mogąrównieżzostać ujawnione właściwym
                                    organom, upoważnionym zgodnie z
                                    obowiązującym prawem.
                                  </p>
                                  <p>
                                    8. Przekazane dane osobowe nie będą
                                    wykorzystywane w celu podejmowania decyzji,
                                    która opiera sięwyłącznie na
                                    zautomatyzowanym przetwarzaniu, w tym
                                    profilowaniu.
                                  </p>
                                  <p>
                                    9. W przypadku, gdy byłoby to niezbędne i
                                    uzasadnione podane dane osobowe
                                    mogąbyćprzesyłane do państwa trzeciego (tj.
                                    państwa innego niżpaństwoczłonkowskie Unii
                                    Europejskiej, Islandia, Liechtenstein
                                    Norwegia lub Szwajcaria) lub organizacji
                                    międzynarodowej, taki
                                    przesyłbędzieodbywałsię z wykorzystaniem
                                    standardowych klauzul umownych
                                    zatwierdzonych przez KomisjęEuropejską, w
                                    celu zapewnienia odpowiedniego poziomu
                                    ochrony danych osobowych wymaganego
                                    przepisami.
                                  </p>
                                  <p>
                                    10. Osobie podającej dane osobowe
                                    przysługuje prawo wniesienia skargi do
                                    organu nadzorczego – Prezesa Urzędu Ochrony
                                    Danych Osobowych (ul. Stawki 2, 00-193
                                    Warszawa).
                                  </p>
                                </ContestFormCollapse>
                                <ButtonCollapse
                                  onClick={toggle}
                                  isopen={isOpen.toString()}
                                >
                                  {!isOpen ? 'ROZWIŃ' : 'ZWIŃ'}
                                </ButtonCollapse>
                              </ContestFormCollapseWrapper>
                            </ContestFormGroup>
                            <ContestFormGroup className="form-group" xs={12}>
                              <div className="form-check">
                                <ContestFormCheckBoxField
                                  type="checkbox"
                                  name="processing"
                                  id="processing"
                                  className="custom-control-input"
                                />
                                <ContestFormCheckBoxLabel
                                  className="custom-control-label"
                                  htmlFor="processing"
                                  error={errors.processing}
                                >
                                  * Wyrażam zgodę na przetwarzanie moich danych
                                  osobowych podanych w powyższym formularzu
                                  przez Carlsberg Polska Sp. z o.o., ul.
                                  Krakowiaków 34, 02-255 Warszawa, w celu
                                  realizacji konkursu „Pieczą otaczamy -
                                  przyrodę uwieczniamy”.
                                </ContestFormCheckBoxLabel>
                              </div>
                            </ContestFormGroup>
                            <ContestFormGroup className="form-group" xs={12}>
                              <div className="form-check">
                                <ContestFormCheckBoxField
                                  type="checkbox"
                                  name="telecommunication"
                                  id="telecommunication"
                                  className="custom-control-input"
                                />
                                <ContestFormCheckBoxLabel
                                  className="custom-control-label"
                                  htmlFor="telecommunication"
                                  error={errors.telecommunication}
                                >
                                  * Wyrażam zgodę na używanie
                                  telekomunikacyjnych urządzeń końcowych i
                                  automatycznych systemów wywołujących w
                                  rozumieniu art. 172 Ustawy z dnia 16 lipca
                                  2004 r. – Prawo telekomunikacyjne dla celów
                                  kontaktu ze zwycięzcami.
                                </ContestFormCheckBoxLabel>
                              </div>
                            </ContestFormGroup>
                            <ContestFormGroup className="form-group" xs={12}>
                              <div className="form-check">
                                <ContestFormCheckBoxField
                                  type="checkbox"
                                  name="marketing"
                                  id="marketing"
                                  className="custom-control-input"
                                />
                                <ContestFormCheckBoxLabel
                                  className="custom-control-label"
                                  htmlFor="marketing"
                                >
                                  Wyrażam zgodę na przetwarzanie przez Carlsberg
                                  Polska Sp. z o.o., ul. Krakowiaków 34, 02-255
                                  Warszawa, podanych przeze mnie danych
                                  osobowych w celach promocyjnych,
                                  informacyjnych, reklamowych i marketingowych
                                  Carlsberg Polska.
                                </ContestFormCheckBoxLabel>
                              </div>
                            </ContestFormGroup>
                            <ContestFormGroup className="form-group" xs={12}>
                              <ContestFormSubmitWrapper>
                                <p>* pola obowiązkowe</p>
                                <Button type="submit" disabled={isSubmitting}>
                                  Wyślij zgłoszenie
                                </Button>
                              </ContestFormSubmitWrapper>
                            </ContestFormGroup>
                          </ContestFormRow>
                        </ContestForm>
                      </>
                    )
                  }}
                </Formik>
              </div>
            </ContestFormColWrapper>
            <ContestFormImageColWrapper>
              <Img fluid={data.contestImage.childImageSharp.fluid} />
            </ContestFormImageColWrapper>
          </Row>
        )}
        <ContestKnowledgeRowWrapper>
          <ContestKnowledgeColWrapper>
            <ContestKnowledgeTitle>
              Spragniony
              <br /> wiedzy?
            </ContestKnowledgeTitle>
          </ContestKnowledgeColWrapper>
          <ContestKnowledgeColWrapper>
            <p>
              Skoro dotarł Waść aż tutaj to znak, żeś spragniony wiedzy. A tak
              się składa, że my o gaszeniu wszelkiego pragnienia wiemy całkiem
              sporo. Tak więc specjalnie dla Waścia kufel pełen ciekawostek na
              temat wody. Na zdrowie!
            </p>
            <Button onClick={handleModalOpen}>więcej o ochronie wody</Button>
          </ContestKnowledgeColWrapper>
        </ContestKnowledgeRowWrapper>
      </Container>
    </ContestWrapper>
  )
}

export default Contest
