import React, { useEffect, useState } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import BackgroundImage from 'gatsby-background-image'
import Img from 'gatsby-image'
import { Parallax } from 'react-scroll-parallax'
import styled from 'styled-components'
import {
  Col,
  Row,
  Container,
  Carousel,
  CarouselItem,
  CarouselIndicators,
} from 'reactstrap'
import { RoughNotation } from 'react-rough-notation'

import { Button, CarouselFadeMixin } from './elements'
import { useWindowSize } from '../lib/hooks'

const MannersWrapper = styled.div``

const MannersBGWrapper = styled(BackgroundImage)`
  background-position-y: top;
`

const MannersDescColWrapper = styled(Col)`
  @media (max-width: 575px) {
    padding-left: 20px;
    padding-right: 20px;
  }
`

const MannersDescWrapper = styled.div`
  padding-bottom: 60px;

  @media (max-width: 575px) {
    padding-bottom: 20px;
  }

  p {
    line-height: 1.4em;

    span {
      color: ${(props) => props.theme.colorWhite};
      z-index: 10;
    }
  }
`

const MannersCarouselWrapper = styled.div`
  ${CarouselFadeMixin}
`

const MannersTitle = styled.h2`
  line-height: 0.85;
  margin-top: 120px;
  margin-bottom: 35px;

  @media (max-width: 575px) {
    text-align: center;
    margin-top: 30px;
  }
  span {
    display: block;
    &:first-of-type {
      color: ${(props) => props.theme.colorRed};
      font-family: ${(props) => props.theme.fontSaaSeriesCD};
      font-size: 90px;
      line-height: 50px;

      @media (min-width: 768px) and (max-width: 991px) {
        font-size: 62px;
      }
      @media (min-width: 576px) and (max-width: 767px) {
        font-size: 62px;
      }
      @media (max-width: 575px) {
        font-size: 36px;
      }
    }
    &:last-of-type {
      color: ${(props) => props.theme.colorGreenLight};
      font-size: 40px;
      font-style: italic;
      padding-left: 1%;
      @media (min-width: 768px) and (max-width: 991px) {
        font-size: 30px;
      }
      @media (min-width: 576px) and (max-width: 767px) {
        font-size: 30px;
      }
      @media (max-width: 575px) {
        font-size: 24px;
      }
    }
  }
`

const MannersCarouselItem = styled(CarouselItem)``

const MannersCarouselText = styled.p`
  display: flex;
  flex-wrap: wrap;
  & > span {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
    &:first-of-type {
      flex: 0 0 90px;
      max-width: 90px;
      font-size: 100px;
      font-style: italic;
      text-align: center;
      line-height: 0.5;
      @media (max-width: 575px) {
        flex: 0 0 70px;
        max-width: 70px;
        font-size: 82px;
      }
    }
    &:last-of-type {
      color: ${(props) => props.theme.colorGreenLight};
      font-size: 32px;
      @media (max-width: 575px) {
        font-size: 24px;
      }
      br {
        @media (max-width: 575px) {
          display: none;
        }
      }
    }
  }
`

const MannersCarouselButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 100px;
  @media (max-width: 575px) {
    margin-top: 30px;
  }
  span {
    color: ${(props) => props.theme.colorBlack};
    font-family: ${(props) => props.theme.fontMontserrat};
    padding-right: 20px;
  }
`

const MannersImageWrapper = styled.div`
  @media (min-width: 992px) {
    position: relative;
    top: -47%;
    left: -40%;
    width: 120%;
  }
  @media (min-width: 992px) and (max-width: 1599px) {
    left: -20%;
  }
  @media (min-width: 768px) and (max-width: 991px) {
    top: -23%;
    left: auto;
    width: 550px;
    margin-top: -300px;
    margin-left: auto;
    margin-right: auto;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    margin-top: -320px;
  }
  @media (max-width: 575px) {
    width: 240px;
    margin-top: -291px;
    margin-left: auto;
    margin-right: auto;
  }
`

const MannersButton = styled(Button)``

const Manners = (props) => {
  const data = useStaticQuery(graphql`
    query {
      mannersBGImage: file(relativePath: { eq: "manners_bg.png" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      mannersManImage: file(relativePath: { eq: "manners_man.png" }) {
        childImageSharp {
          fluid(maxWidth: 740) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  `)

  const items = [
    {
      text: 'Na deszczówkę mieć<br /> baczenie obiecuję.',
    },
    {
      text: 'Gdy żar się leje z nieba,<br /> wody pszczołom polewam.',
    },
    {
      text: 'Podczas rozmowy i kąpieli<br /> wody bez umiaru lać nie będę.',
    },
    {
      text: 'Kiedy o dobro rzeki chodzi,<br /> wody w usta nie nabiorę.',
    },
    {
      text: 'Wodę oszczędzać będę,<br /> czyste sumienie zachowam.',
    },
  ]

  const [activeIndex, setActiveIndex] = useState(0)
  const [animating, setAnimating] = useState(false)

  const next = () => {
    if (animating) return
    const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1
    setActiveIndex(nextIndex)
  }

  const previous = () => {
    if (animating) return
    const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1
    setActiveIndex(nextIndex)
  }

  const goToIndex = (newIndex) => {
    if (animating) return
    setActiveIndex(newIndex)
  }

  const slides = items.map((item, index) => {
    return (
      <MannersCarouselItem
        onExiting={() => setAnimating(true)}
        onExited={() => setAnimating(false)}
        key={index}
        className="row"
      >
        <Col>
          <MannersCarouselText>
            <span>
              <span>{index + 1}.</span>
            </span>
            <span dangerouslySetInnerHTML={{ __html: item.text }} />
          </MannersCarouselText>
        </Col>
      </MannersCarouselItem>
    )
  })

  const [parallaxEnabled, setParallaxEnabled] = useState(true)
  const size = useWindowSize()
  useEffect(() => {
    if (typeof size.width !== 'undefined' && size.width < 992) {
      setParallaxEnabled(false)
    } else {
      setParallaxEnabled(true)
    }
  }, [size])

  return (
    <MannersWrapper>
      <MannersBGWrapper
        Tag="div"
        className="mannersBGImage"
        fluid={data.mannersBGImage.childImageSharp.fluid}
        backgroundColor={`transparent`}
      >
        <Container>
          <Row>
            <Col lg={6}>
              <Parallax y={[-86, 6]} tagOuter="div" disabled={!parallaxEnabled}>
                <MannersImageWrapper id="manners">
                  <Img fluid={data.mannersManImage.childImageSharp.fluid} />
                </MannersImageWrapper>
              </Parallax>
            </Col>
            <MannersDescColWrapper lg={6}>
              <MannersDescWrapper>
                <MannersTitle>
                  <span>KASZTELAŃSKI</span> <span>poczet obyczajów</span>
                </MannersTitle>
                <p>
                  Witaj Waść! Skoro tu{'\u00A0'}jesteś to{'\u00A0'}niechybny
                  znak, że{'\u00A0'}masz baczenie na otoczenie. To{'\u00A0'}tak
                  jak my!{' '}
                  <RoughNotation
                    show={true}
                    color="#adc8b7"
                    animate={false}
                    padding={2}
                    type="highlight"
                    multiline={true}
                  >
                    Troska o{'\u00A0'}środowisko to{'\u00A0'}ważki element
                    naszej działalności.
                  </RoughNotation>{' '}
                  Dlatego zamiast lać wodę, wolimy się o{'\u00A0'}nią
                  zatroszczyć. Ty{'\u00A0'}Waść też możesz!{' '}
                  <strong>
                    Poznaj Kasztelański Poczet Obyczajów! To kilka prostych
                    zasad.{' '}
                  </strong>
                  Jeśli i{'\u00A0'}Ty{'\u00A0'}się pod nimi podpisujesz{' '}
                  <strong>to{'\u00A0'}kufel wznieś!</strong>
                </p>
              </MannersDescWrapper>
              <MannersCarouselWrapper>
                <Carousel
                  activeIndex={activeIndex}
                  next={next}
                  previous={previous}
                  interval={false}
                  items={slides}
                  className="carousel-fade"
                >
                  {false && (
                    <CarouselIndicators
                      items={items}
                      activeIndex={activeIndex}
                      onClickHandler={goToIndex}
                    />
                  )}
                  {slides}
                </Carousel>
              </MannersCarouselWrapper>
              <MannersCarouselButtonWrapper>
                <span>{`${activeIndex + 1} z ${slides.length}`}</span>{' '}
                <MannersButton onClick={() => next()}>następny</MannersButton>
              </MannersCarouselButtonWrapper>
            </MannersDescColWrapper>
          </Row>
        </Container>
      </MannersBGWrapper>
    </MannersWrapper>
  )
}

export default Manners
