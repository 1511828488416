import React, { useState } from 'react'
import { Link, useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import { Link as LinkScroll } from 'react-scroll'
import styled from 'styled-components'
import { Collapse, Navbar, NavbarToggler, Nav, NavItem } from 'reactstrap'

import navLinkCrownIcon from '../images/crown_icon.svg'
import navLinkConeIcon from '../images/cone_icon.svg'

const NavbarHeaderWrapper = styled.div`
  padding-top: 100px;
  @media (max-width: 575px) {
    padding-top: 40px;
  }
`

const NavbarHeaderLogoWrapper = styled.div`
  width: 168px;
  height: 118px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 40px;
  @media (max-width: 575px) {
    width: 134px;
    height: 94px;
  }
`

const NavbarHeaderNav = styled(Navbar)`
  @media (max-width: 767px) {
    display: none;
  }
`

const NavbarHeaderNavItem = styled(NavItem)`
  position: relative;
  color: ${(props) => props.theme.colorWhite}!important;
  font-family: ${(props) => props.theme.fontMontserrat};
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;
  @media (min-width: 992px) and (max-width: 1199px) {
    font-size: 14px;
  }
  @media (min-width: 768px) and (max-width: 991px) {
    font-size: 12px;
  }
  &:nth-child(odd) {
    a {
      &:after {
        background-image: url(${navLinkCrownIcon});
      }
    }
  }
  &:nth-child(even) {
    a {
      &:after {
        background-image: url(${navLinkConeIcon});
      }
    }
  }
  &:last-child {
    a {
      &:after {
        content: none;
      }
    }
  }
  a {
    color: ${(props) => props.theme.colorWhite}!important;
  }
`

const NavbarHeaderNavLink = styled(LinkScroll)`
  position: relative;
  padding-left: 50px !important;
  padding-right: 50px !important;
  @media (min-width: 992px) and (max-width: 1199px) {
    padding-left: 40px !important;
    padding-right: 40px !important;
  }
  @media (min-width: 768px) and (max-width: 991px) {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
  &:after {
    content: '';
    position: absolute;
    top: 50%;
    right: 0;
    width: 21px;
    height: 18px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    transform: translate(50%, -50%);
    z-index: 20;
    @media (min-width: 992px) and (max-width: 1199px) {
      width: 19px;
      height: 16px;
    }
    @media (min-width: 768px) and (max-width: 991px) {
      width: 16px;
      height: 13px;
    }
  }
`

const NavbarHeader = () => {
  const [isOpen, setIsOpen] = useState(false)
  const data = useStaticQuery(graphql`
    query {
      logoImage: file(relativePath: { eq: "logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 185) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  `)

  const toggle = () => setIsOpen(!isOpen)

  return (
    <NavbarHeaderWrapper>
      <NavbarHeaderLogoWrapper>
        <Link to="/">
          <Img fluid={data.logoImage.childImageSharp.fluid} />
        </Link>
      </NavbarHeaderLogoWrapper>
      <NavbarHeaderNav light expand="md">
        <NavbarToggler onClick={toggle} />
        <Collapse isOpen={isOpen} navbar>
          <Nav className="m-auto" navbar>
            <NavbarHeaderNavItem>
              <NavbarHeaderNavLink
                className="nav-link"
                href="#water"
                to="water"
                smooth
              >
                WODA
              </NavbarHeaderNavLink>
            </NavbarHeaderNavItem>
            <NavbarHeaderNavItem>
              <NavbarHeaderNavLink
                className="nav-link"
                href="#manners"
                to="manners"
                smooth
              >
                PIWO
              </NavbarHeaderNavLink>
            </NavbarHeaderNavItem>
            <NavbarHeaderNavItem>
              <NavbarHeaderNavLink
                className="nav-link"
                href="#gardens"
                to="gardens"
                smooth
              >
                OGRODY
              </NavbarHeaderNavLink>
            </NavbarHeaderNavItem>
            <NavbarHeaderNavItem>
              <NavbarHeaderNavLink
                className="nav-link"
                href="#rainwater"
                to="rainwater"
                smooth
              >
                DESZCZÓWKA
              </NavbarHeaderNavLink>
            </NavbarHeaderNavItem>
            <NavbarHeaderNavItem>
              <NavbarHeaderNavLink
                className="nav-link"
                href="#manners"
                to="manners"
                smooth
              >
                OBYCZAJE
              </NavbarHeaderNavLink>
            </NavbarHeaderNavItem>
            <NavbarHeaderNavItem>
              <NavbarHeaderNavLink
                className="nav-link"
                href="#contest"
                to="contest"
                offset={-50}
                smooth
              >
                KONKURSY
              </NavbarHeaderNavLink>
            </NavbarHeaderNavItem>
          </Nav>
        </Collapse>
      </NavbarHeaderNav>
    </NavbarHeaderWrapper>
  )
}

export default NavbarHeader
