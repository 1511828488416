import React, { useState } from 'react'
import styled from 'styled-components'
import { Col, Row, Carousel, CarouselItem } from 'reactstrap'

import AdvantageItem from './advantage-card-item'

import {
  CarouselFadeMixin,
  CarouselControlWrapper,
  CarouselControl,
  CarouselControlItem,
} from './elements'

const AdvantageCarouselMobiledWrapper = styled(Col)`
  ${CarouselFadeMixin}
`

const AdvantageCarouselMobileItem = styled(AdvantageItem)`
  @media (max-width: 575px) {
    flex: 0 0 100%;
    max-width: 100%;
    padding-top: 30px;
  }
`

const AdvantageCarouselMobileWrapperIndicator = styled.div`
  width: 100%;
  color: ${(props) => props.theme.colorBlack};
  font-family: ${(props) => props.theme.fontMontserrat};
  text-align: center;
  font-size: 22px;
  margin-bottom: 36px;
`

const AdvantageCarouselMobileControlWrapper = styled(CarouselControlWrapper)``

const AdvantageCarouselMobileControl = styled(CarouselControl)``

const AdvantageCarouselMobileControlItem = styled(CarouselControlItem)``

const AdvantageCarouselMobile = (props) => {
  const items = props.listItems
  const [activeIndex, setActiveIndex] = useState(0)
  const [animating, setAnimating] = useState(false)

  const type = props.type ? props.type : null
  const images = props.images ? props.images : null

  const next = () => {
    if (animating) return
    const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1
    setActiveIndex(nextIndex)
  }

  const previous = () => {
    if (animating) return
    const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1
    setActiveIndex(nextIndex)
  }

  const goToIndex = (newIndex) => {
    if (animating) return
    setActiveIndex(newIndex)
  }

  const slides = items.map((_, index) => {
    return (
      <CarouselItem
        onExiting={() => setAnimating(true)}
        onExited={() => setAnimating(false)}
        key={index}
      >
        <AdvantageCarouselMobileItem
          type={type}
          index={index}
          items={items}
          images={images}
        />
      </CarouselItem>
    )
  })

  return (
    <AdvantageCarouselMobiledWrapper>
      <Row>
        <Carousel
          activeIndex={activeIndex}
          next={next}
          previous={previous}
          interval={false}
          className="carousel-fade"
        >
          {slides}
        </Carousel>
        <AdvantageCarouselMobileWrapperIndicator>
          <span>{activeIndex + 1}</span> <span>z</span>{' '}
          <span>{slides.length}</span>
        </AdvantageCarouselMobileWrapperIndicator>
        <AdvantageCarouselMobileControlWrapper>
          <AdvantageCarouselMobileControl>
            {items.map((_, index) => {
              return (
                <AdvantageCarouselMobileControlItem
                  key={index}
                  index={index}
                  current={activeIndex}
                  onClick={() => goToIndex(index)}
                >
                  <span className="number">
                    <span>{index + 1}</span>
                  </span>
                </AdvantageCarouselMobileControlItem>
              )
            })}
          </AdvantageCarouselMobileControl>
        </AdvantageCarouselMobileControlWrapper>
      </Row>
    </AdvantageCarouselMobiledWrapper>
  )
}

export default AdvantageCarouselMobile
