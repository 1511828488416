import React, { useState } from 'react'
import { ModalBody } from 'reactstrap'

import {
  CustomModal,
  CustomModalHeader,
  CustomModalCloseButton,
  CustomModalFooter,
} from './elements'

export default ({ item, isShow, handleToggle }) => {
  const [isOpen, setIsOpen] = useState(isShow)

  const toggle = () => {
    handleToggle()
    setIsOpen(!isOpen)
  }

  return (
    <CustomModal
      isOpen={isOpen}
      toggle={toggle}
      className={'modal-dialog-centered modalAdvantageCard'}
    >
      <CustomModalHeader
        toggle={toggle}
        close={
          <CustomModalCloseButton onClick={toggle}>
            ZAMKNIJ X
          </CustomModalCloseButton>
        }
      >
        <span>{item.title}</span>
      </CustomModalHeader>

      {typeof item.text === 'object' ? (
        <ModalBody>{item.text}</ModalBody>
      ) : (
        <ModalBody dangerouslySetInnerHTML={{ __html: item.text }} />
      )}

      <CustomModalFooter></CustomModalFooter>
    </CustomModal>
  )
}
