import React, { useEffect, useState } from 'react'
import Cookies from 'js-cookie'
import styled from 'styled-components'
import { Col, Row, Container } from 'reactstrap'

import { Button } from './elements'

const CookieBarWrapper = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #9f853f;
  padding-top: 20px;
  padding-bottom: 5px;
  z-index: 9999;
  ${(props) =>
    props.show
      ? `
    display: block;
  `
      : `
    display: none;
  `}
  @media (max-width: 991px) {
    padding-bottom: 10px;
  }
`

const CookieBarContainer = styled(Container)`
  @media (min-width: 1200px) {
    max-width: 1085px !important;
  }
  @media (max-width: 575px) {
    padding-left: 20px;
    padding-right: 20px;
  }
`

const CookieBarColDesc = styled(Col)`
  color: ${(props) => props.theme.colorWhite};
  font-size: 11px;
  text-align: justify;
`

const CookieBarButtonWrapper = styled(Col)`
  text-align: right;
  @media (max-width: 991px) {
    text-align: center;
  }
`

const CookieBarButton = styled(Button)`
  padding-left: 35px;
  padding-right: 35px;
  @media (min-width: 992px) {
    font-size: 24px;
    margin-top: -30px;
  }
  @media (min-width: 768px) and (max-width: 991px) {
    font-size: 22px;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    font-size: 20px;
  }
  @media (max-width: 575px) {
    font-size: 18px;
    padding-left: 25px;
    padding-right: 25px;
  }
  &:after {
    content: none;
  }
`

const CookieBar = () => {
  const [isShown, setIsShown] = useState(false)
  const handleClose = (e) => {
    e.preventDefault()
    Cookies.set('cookiesAccepted', +new Date())
    setIsShown(false)
  }

  useEffect(() => {
    let cookiesAccepted = Cookies.get('cookiesAccepted')
    if (typeof cookiesAccepted === 'undefined') {
      setIsShown(true)
    }
  }, [])

  return (
    <CookieBarWrapper show={isShown}>
      <CookieBarContainer>
        <Row>
          <CookieBarColDesc xs={12}>
            <p>
              W ramach naszej witryny stosujemy pliki cookies w celu zbierania
              informacji, które pomagają nam usprawnić obsługę witryny,
              dostosować nasze reklamy do Twoich preferencji oraz ulepszyć nasze
              produkty i usługi.
            </p>
            <p>
              Zgadzając się, akceptujesz zasady dot. prywatności i plików
              cookies obowiązujące w niniejszej witrynie internetowej.
              <br />
              Ustawienia cookies (w tym ich blokadę) możesz zmieniać w
              ustawieniach Twojej przeglądarki w każdej chwili.
            </p>
          </CookieBarColDesc>
          <CookieBarButtonWrapper xs={12}>
            <CookieBarButton onClick={handleClose}>Akceptuje</CookieBarButton>
          </CookieBarButtonWrapper>
        </Row>
      </CookieBarContainer>
    </CookieBarWrapper>
  )
}

export default CookieBar
